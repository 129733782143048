import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'mbpFilterOrder' })

export class MbpFilterOrderPipe implements PipeTransform {
  transform(value, selectedFiltersMap) {
    let newFilters = [...value].sort((a, b) => {
      let [idA, valuesA] = [a.value.id, a.value.values];
      let [idB, valuesB] = [b.value.id, b.value.values];
      let isSelectedA = false;
      valuesA.forEach((el) => {
        if (this.isFilterSelected(idA, el, selectedFiltersMap)) isSelectedA = true;
      });
      let isSelectedB = false;
      valuesB.forEach((el) => {
        if (this.isFilterSelected(idB, el, selectedFiltersMap)) isSelectedB = true;
      });
      return isSelectedA ? (isSelectedB ? 1 : -1) : 0;
    });
    return newFilters;
  }

  isFilterSelected(filterId, valueSelected, selectedFiltersMap) {
    let item = selectedFiltersMap.get(filterId);
    return item && item.filterItems ? item.filterItems.includes(valueSelected) : false;
  }
}