import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Card } from '../../shared/_models/card.model';
import { CardContextMenu } from '../../shared/_models/card-context-menu';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'fpc-reactions',
  templateUrl: './reactions.component.html',
  styleUrls: ['./reactions.component.scss']
})
export class ReactionsComponent {

  buttonLayoutType: string;

  @Input() cardModel: Card;
  @Input() cardContextMenu: CardContextMenu;
  @Input() menuPlacement: string[] | string;
  @Input() reaction: any;


  @Input() id: string;
  @Input() buttonColorClass: string = 'outline-primary';
  @Input() buttonClass!: string;
  @Input() buttonText: string;
  @Input() buttonMenuPlacement: string | string[];
  @Input() iconSize: string;
  @Input() iconClass: string;
  @Input() reactionLayout: string;
  @Input() showFavouriteButton: boolean;
  @Input() isFavourite: boolean;
  @Input() widgetSectionExists: boolean;

  hoverClass: string | undefined;
  @Output() onFavouriteAdd: EventEmitter<any> = new EventEmitter<any>()
  @Output() onFavouriteRemove: EventEmitter<any> = new EventEmitter<any>()
  @Output() onReactionSelect: EventEmitter<any> = new EventEmitter<any>()

  constructor(private sanitizer: DomSanitizer) { }

  public getSantizeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  onFavouriteAddClick(event?, cardMenu?) {
    this.onFavouriteAdd.emit({ event, cardMenu })
  }

  onFavouriteRemoveClick(event?, cardMenu?) {
    this.onFavouriteRemove.emit({ event, cardMenu })
  }

  onReactionSelectClick(reaction) {
    this.onReactionSelect.emit(reaction)
  }
}
