import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from '../../buttons/button/button.module';
import { InputSelectComponent } from './input-select.component';
import { DropdownModule } from '../../dropdown/dropdown.module';
import { InputFieldLabelModule } from '../input-field-label/input-field-label.module';
import { InputErrorModule } from '../input-error/input-error.module';

@NgModule({
  declarations: [InputSelectComponent],
  imports: [CommonModule, NgbModule, ReactiveFormsModule, ButtonModule, DropdownModule, InputFieldLabelModule, InputErrorModule],
  exports: [InputSelectComponent],
})
export class InputSelectModule {}
