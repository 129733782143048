import { Component, Injector, Input, forwardRef } from '@angular/core';
import { BaseInputComponent } from '../base-input.directive';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'fpc-input-error',
  templateUrl: './input-error.component.html',
  styleUrls: ['./input-error.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputErrorComponent),
      multi: true,
    },    
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputErrorComponent),
      multi: true,
    }
  ]
})

export class InputErrorComponent extends BaseInputComponent {
  
  @Input() formControl;

  constructor(injector: Injector) {
    super(injector);
  }
 }