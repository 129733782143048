<div class="bg-white">
    <!-- Header -->
    <div class="d-flex justify-content-between align-items-center flex-wrap py-3 px-4 gap-5">
        <h4 class="mb-0 fw-bold">{{title}}<span class="text-gray ms-2" *ngIf="subTitle">{{subTitle}}</span></h4>

        <ng-container *ngIf="key">
            <div *ngFor="let item of key" class="module flex-fill p-2 border rounded border-gray-lighter">
                <div class="d-flex gap-3 align-items-center justify-content-center">
                    <i [class]="item.iconClass + ' ' +  item.colorClass"></i>
                    <h4 class="mb-0 fw-bold text-truncate cursor-default">{{item.label}}</h4>
                </div>
            </div>
        </ng-container>

        <!-- Controls -->
        <div class="d-flex gap-3">
            <fpc-button 
                title="'Search'" 
                [buttonLayoutType]="'icon'" 
                [buttonColorClass]="'primary'" 
                [iconClass]="'fal fa-search'" 
                (buttonClick)="viewSearch = !viewSearch"
                [active]="viewSearch">
            </fpc-button>

            <div class="vr"></div>

            <fpc-button 
                title="'Legend view'" 
                [buttonLayoutType]="'icon'" 
                [buttonColorClass]="'primary'" 
                [iconClass]="'fal fa-th'"
                (buttonClick)="legendViewMode = LEGEND_VIEW_MODE.LEGEND_VIEW" 
                [active]="legendViewMode == LEGEND_VIEW_MODE.LEGEND_VIEW">
            </fpc-button>

            <fpc-button 
                title="'List view'" 
                [buttonLayoutType]="'icon'" 
                [buttonColorClass]="'primary'" 
                [iconClass]="'fal fa-th-list'"
                (buttonClick)="legendViewMode = LEGEND_VIEW_MODE.LIST_MODE" 
                [active]="legendViewMode != LEGEND_VIEW_MODE.LEGEND_VIEW">
            </fpc-button>

        </div>
        <!-- X Controls -->
    </div>
    <!-- X Header -->

    <div class="p-4 gap-5 align-items-center border-top" [ngClass]="viewSearch ? 'd-flex': 'd-none '">
        <h4 class="mb-0 fw-bold">Search</h4>
        <fpc-input-text 
            [label]="_label" 
            [placeholder]="legendPlaceHolder" 
            class="flex-fill" 
            [formControl]="formControl">
        </fpc-input-text>
    </div>

    <!-- Content  -->
    <div *ngIf="filteredAndSortedData?.length === 0" class="p-5 d-flex flex-column justify-content-center align-items-center text-center border-top">
        <h3 class="fw-bold">Nothing found
            <h4 class="text-gray-dark">Nothing has been found related to your search. 
                <br>Please try searching a different term or 
                <a href="" (click)="clearLegendSearch($event)">clear the search</a>.
            </h4>
        </h3>
    </div>

    <div *ngIf="filteredAndSortedData?.length !== 0" [ngClass]="legendViewMode == LEGEND_VIEW_MODE.LEGEND_VIEW ? 'legend-view' : 'list-view'" class="border-top">
        <ng-container *ngFor="let item of filteredAndSortedData">
            <div 
            [title]="item.label" role="button" class="module d-flex gap-3 align-items-center" 
            [ngClass]="[legendClass(item.label) ? '' : 'opacity-50']" 
            (click)="
                (application=='MBP' || application=='assessments')? selectMBPLegendItem(item.label) : 
                application=='SUSBAR' ? selectSUSBARLegendItem(item.label) : 
                application =='MAP-VIEW'? selectStudioItem(item.label, item.value) : selectLegendItem(item.label)"
            >   
                <i 
                    [class]="'fa-sharp fa-solid fa-circle-small ' + item.colorClass"
                    [ngStyle]="{'color': 
                        (highlight == 'project-name' || highlight == 'people') ? 
                        (_selectedItemsArray.includes(item.label) ? '#EE7F00' : (item.colorClass.includes('#') ?  item.colorClass : ('#'+item.colorClass))) : 
                        (item.colorClass.includes('#') ?  item.colorClass : ('#'+item.colorClass))
                    }">
                </i>
                <h4 class="label mb-0 fw-bold text-truncate">{{item.label}}</h4>
            </div>
        </ng-container>
    </div>
    <!-- X Content -->

</div>