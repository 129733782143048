<fpc-fpcommon></fpc-fpcommon>
<div class="fp-header">
  <div *ngIf="!isHome" style="padding: 2rem 0 2rem 2.5rem; display: flex;" class="d-lg-none">
    <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#nav-wrapper" aria-controls="nav-wrapper" aria-expanded="false"
      aria-label="Toggle navigation">
      <span class="icon-bar top-bar"></span>
      <span class="icon-bar middle-bar"></span>
      <span class="icon-bar bottom-bar"></span>
    </button>
  </div>

  <div class="logo" routerLink="" style="cursor: pointer">
    <img src="https://designassets.fosterandpartners.com/assets/images/svg/FP_Logo_Desktop.svg" alt="" />
  </div>
</div>

<router-outlet></router-outlet>