import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BookingTableComponent } from './booking-table.component';
import { FilterPipe } from '../../shared/_pipes/filter.pipe';
import { SmartSharedModule } from '../../shared/smart-shared.module';



@NgModule({
  declarations: [
    BookingTableComponent
  ],
  imports: [
    CommonModule,
    SmartSharedModule
  ], 
  exports: [
    BookingTableComponent
  ],
  
  providers:[FilterPipe] 
})
export class BookingTableModule { }
