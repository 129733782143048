import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'fpc-flag',
  templateUrl: './flag.component.html',
  styleUrls: ['./flag.component.scss']
})
export class FlagComponent implements OnInit {

  @Input() color: 'white'|'gray-darker'| 'blue' | 'red' | 'medium-orange' | 'green' | 'dark-green' | 'gray' = 'white';
  @Input() text: string;
  
  constructor() { }

  ngOnInit(): void {
  }

}
