import { Injectable, InjectionToken } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Subject } from 'rxjs';
import { User } from '../../../presentational/shared/_models/user.model';
import { UserConstants } from '../../../presentational/shared/_constants/user.constants';


export const USER_SERVICE = new InjectionToken<UserService>("UserService");

export interface UserService {
  userInformation: any;
  userConfig: any;
  userInformationReadyEvent: Subject<any>;
}

export class MockUserService { }