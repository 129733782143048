import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputFieldLabelComponent } from './input-field-label.component';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { InputErrorModule } from '../input-error/input-error.module';
import { ButtonModule } from '../../buttons/button/button.module';

@NgModule({
  declarations: [InputFieldLabelComponent],
  imports: [CommonModule, FormsModule, NgbPopoverModule, InputErrorModule, ButtonModule],
  exports: [InputFieldLabelComponent],
})
export class InputFieldLabelModule {}
