<div>
 
  <ul [class]="'list-unstyled bg-white p-0 m-0 ' + (borderClass ? borderClass : '') ">
    <li
      *ngFor="let link of model; let first = first"
      [class]="
      (model.length == 1 ? 'single-element ' : '') + 
      (borderClass ? borderClass : '') + ' p-2 ' + 
      (linkPosition ? linkPosition : '') + ' ' + 
      (linkDecoration? linkDecoration : '') + ' ' + 
      (linkClass ? linkClass : '') + ' ' + 
      (first ? 'first' : '')" 
      [ngStyle]="customNgStyle" 
    >    

      <a (click)="linkAction($event)" role="button">       
        <p [class]="(fontSize ? fontSize : '') + ' text-turquoise m-0'">{{ link.content }}</p>
      </a>
    </li>
  </ul>
  
</div>
