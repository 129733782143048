import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeedbackFormWidgetComponent } from './feedback-form-widget.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ButtonModule } from '../../../presentational/components/buttons/button/button.module';
import { InputFieldLabelModule } from './../../../presentational/components/form-elements/input-field-label/input-field-label.module';
import { DropdownModule } from '../../../presentational/components/dropdown/dropdown.module';
import { InputTextareaModule } from '../../../presentational/components/form-elements/input-textarea/input-textarea.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WidgetContainerModule } from '../../../presentational/components/widget-container/widget-container.module';
import { PreloaderModule } from '../../../presentational/components/preloader/preloader.module';

@NgModule({
  declarations: [
    FeedbackFormWidgetComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    ButtonModule,
    InputFieldLabelModule,
    DropdownModule,
    InputTextareaModule,
    WidgetContainerModule,
    FormsModule,
    ReactiveFormsModule,
    PreloaderModule
  ],
  exports: [
    FeedbackFormWidgetComponent
  ]
})
export class FeedbackFormWidgetModule { }
