  <div class="d-flex flex-column gap-3" style="overflow: initial;">

      <div ngbDropdown class="dropdown show-tick button-action-group"
        *ngFor="let filter of _filtersItems | keyvalue:asIsOrder | mbpFilterOrder:_selectedFiltersMap; let filtInd = index; ">
        <ng-container *ngIf="filtInd <= (expandActive || scrollable ? 999 : 5) || screenWidth < 1024">        
          
          <div class="d-flex flex-fill gap-3">      
            <div class="col-10">
                <fpc-dropdown
                  class="w-100" 
                  [autoclose]="'outside'" 
                  [buttonActive]="_selectedFiltersMapInital.has(filter.value.id)"
                  [id]="'dropdownMenuButton_'+filter.value.id"  
                  [customNgClass]="[_selectedFiltersMapInital.has(filter.value.id)?'active':'', filter.value.values.length==0 && !searchText || filter.value.badge == '-11' ?'disabled':'', filter.value.badge == '-1' ? 'hide-after-arrow' : '']"
                  buttonText="{{_selectedFiltersMapInital.has(filter.value.id) ? (filter.value.name == 'Group' ?
                  'Studio / Group / Team' : filter.value.label) + ': ' :
                  ''}}
                  {{_selectedFiltersMapInital.has(filter.value.id) ? getFilterBtnTitle(filter.value.id, false) :
                  (getFilterBtnTitle(filter.value.id,true)
                  || filter.value.label)}}"
                  [title]="filter.value.label" 
                  [buttonClass]="'w-100 text-start'"
                  (onButtonClick)="resetFilterValues(filter.value.id); selectCategoryIfDisabled($event, filter);">
  
            <div *ngIf="showDatePicker" class="tab-menu" [ngClass]="[tabMenuClass]">
                <div class="title search-tab-menu" (click)="tabMenuClass = 'search-active'" [ngClass]="[tabMenuClass == 'search-active' ? 'data-active' : '']">
                  <h4><strong>Search</strong></h4>
                </div>
                <div class="title date-tab-menu" (click)="tabMenuClass = 'date-active'" [ngClass]="[tabMenuClass == 'date-active' ? 'data-active' : '']">
                  <h4><strong>Date</strong></h4>
                </div>
            </div>              
                            
           <fpc-input-search                     
             [formControl]="inputSearch"
             [searchList]="filter.value.values"              
             [searchPlaceholder]="filter.value.title"
             [startWithFullList]="true"
             [inputSize]="'sm'"
             (selectedValues)="applyFilterSelection($event, filter.value.id)"
            ></fpc-input-search>      
                            
            <div class="date-tab">
              <app-date *ngIf="showDatePicker" (getFilterSelectionChanged)="onDateSelectionChanged($event, filter.value)" [selectedDate]="filter.value.selectedDate">
              </app-date>
            </div>
                                          
         </fpc-dropdown>  
            </div>
            <div class="col-2">
              <fpc-button          
                [buttonLayoutType]="'icon'" 
                [buttonColorClass]="'outline-primary'" 
                [buttonClass]="'w-100 h-100 px-4'"
                [iconClass]="(filter.value.selectedDate && filter.value.selectedDate.fromDate && filter.value.selectedDate.toDate) ? 'fal fa-calendar-check' : 'fal fa-check'"
                [title]="areAllFiltersSelected(filter.value.id, filter.value.values) ? 'Deselect all' : 'Select all'"
                [active]="areAllFiltersSelected(filter.value.id, filter.value.values)"
                [disabled]="filter.value.values.length==0 && !searchText || filter.value.badge == '-11'"
                (buttonClick)="filter.value.badge == '-1' ? selectCategoryIfDisabled($event, filter) : (areAllFiltersSelected(filter.value.id, filter.value.values) ? onDeSelectAll($event, filter.value, true) : onSelectAll($event, filter.value, true))"
              ></fpc-button> 
            </div>                 
          
          
        </div>
        </ng-container>
      </div>     

    </div>
    <div *ngIf="_filtersItems.size > 6 && !scrollable" class="controls col-12" [ngClass]="expandActive ? 'expanded' : ''" style="display: block !important;">
     
      <fpc-button 
        [buttonLayoutType]="'icon'" 
        [buttonColorClass]="'outline-primary'" 
        [buttonClass]="'text-center w-100 p-2'" 
        [active]="expandActive"
        [iconClass]="'fal fa-chevron-down'" 
        (buttonClick)="expandActive = !expandActive">
      </fpc-button>
    
    </div>