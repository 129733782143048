
import { Injectable} from '@angular/core';
import { Subject } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class AccordionService {
    recheckCollapsedItems: Subject<any> = new Subject();
}