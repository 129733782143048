import { PipeTransform, Pipe, Injectable } from '@angular/core';

//filter.ts
@Pipe({
  name: 'filter'
})
@Injectable({
  providedIn: 'root'
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], values: string[], key: string, key2: string) {
    if (!items || items.length == 0 || !values || values.length == 0) {
      return items;
    }
    else return items.filter(x => values.includes(x[key][key2]));
  }
}