import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-preview-wrapper',
  templateUrl: './preview-wrapper.component.html',
  styleUrls: ['./preview-wrapper.component.scss']
})
export class PreviewWrapperComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
