<ng-container *ngIf="_label">
  <fpc-input-field-label
    [label]="_label"
    [labelAdditionalInfo]="_labelAdditionalInfo" 
    [required]="isRequired" 
    [popoverText]="_popoverText" 
    [popoverTitle]="_popoverTitle"
    [labelColor]="labelColor"
    [labelClass]="labelClass">
    
    <ng-container [ngTemplateOutlet]="t"></ng-container>
      
  </fpc-input-field-label>
  <fpc-input-error [formControl]="control" [customErrors]="_customErrors"></fpc-input-error>
</ng-container>

<ng-container *ngIf="!_label" [ngTemplateOutlet]="t"></ng-container>

<ng-template #t>
  <fpc-dropdown 
        [id]="id" 
        [buttonText]="customText ? customText : inputButtonText"
        [buttonColorClass]="'dropdown-input'"
        [buttonClass]="'w-100'" 
        [disabled]="formControl.status == 'DISABLED' ? true: false"
        [container]="container"
        (isOpened)="onDropdownOpen($event)"
        >
  
        <ng-content></ng-content>
  
      </fpc-dropdown>
</ng-template>