<fpc-input-field-label 
  [label]="_label"
  [labelAdditionalInfo]="_labelAdditionalInfo" 
  [required]="required" 
  [popoverText]="_popoverText" 
  [popoverTitle]="_popoverTitle"
  [labelColor]="labelColor"   
  [labelClass]="labelClass" 
  [iconSize]="iconSize">
    <input 
      [id]="id" 
      type="number" 
      step="0.01"
      [max]="max"
      [min]="min"
      [class]="'form-control mb-0 ' + ((formControl.invalid && formControl.touched) ? 'border-danger' : '')" 
      [ngClass]="inputClass" 
      [formControl]="control"
      [placeholder]="_placeholder" 
      [name]="control" 
      [required]="required"/>

</fpc-input-field-label>
<fpc-input-error [formControl]="control" [customErrors]="_customErrors"></fpc-input-error>