import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'fpc-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.scss'],
})
export class StepsComponent implements OnInit {
  @Input() public totalSteps: number;
  @Input() public currentStep: number;
  @Input() public showBorder: boolean = true;
  steps: number[] = [];

  constructor() {}

  ngOnInit(): void {
    for (let i = 1; i <= this.totalSteps; i++) {
      this.steps.push(i);
    }
  }
}
