import { IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { SharedConfig } from '../../msal';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

export const MSALInstanceFactory = (config: SharedConfig): IPublicClientApplication => {
  return new PublicClientApplication({
    auth: {
      clientId: config.Client_Id,
      redirectUri: config.Redirect_Uri,
      authority: `https://login.microsoftonline.com/${config.Tenant_Id}`,
      postLogoutRedirectUri: config.Post_Logout_Redirect_Uri,
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    /* system: {
      loggerOptions: {
        loggerCallback: (level: LogLevel, message: string, containsPii: boolean): void => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case LogLevel.Error:
              console.error(message);
              return;
            case LogLevel.Info:
              console.info(message);
              return;
            case LogLevel.Verbose:
              console.debug(message);
              return;
            case LogLevel.Warning:
              console.warn(message);
              return;
          }
        },
        piiLoggingEnabled: false
      }
    } */
  });
}