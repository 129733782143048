import { Component, OnInit, Input, Output, EventEmitter, AfterContentInit, HostListener } from '@angular/core';
import { Event, NavigationStart, Router } from '@angular/router';
import { NavBar } from '../../shared/_models';


@Component({
  selector: 'fpc-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, AfterContentInit {
  @Input() model: NavBar;
  active: any;
  isMobile;

  @Input() isHomePage: boolean;

  @Input() userProfilePic: string = 'https://designassets.fosterandpartners.com/assets/images/people/User_00.jpg';
  @Input() userIcon: string;

  @Input() hamburgerOpened: boolean = false;
  @Input() profilePanelOpened: boolean = false;
  @Input() mobileSidebarOpened: boolean = false;
  @Input() sidebarCollapsed: boolean = false;
  @Input() preferencesSelectionPanelOpened: boolean = false;
  @Input() notificationsPanelOpened: boolean = false;
  @Input() favPanelOpened: boolean = false;
  @Input() pageSize: number;
  _title: string;
  @Input() set title(value: string) {
    this.addTitleStyleTag(value);
    this._title = value;
  };
  get title() {
    return this._title;
  }
  @Input() holidayIconConfigs: { startDate: Date, endDate: Date, addition: string }[] = [];

  @HostListener('window:resize')
  updateIsMobile() {
    this.isMobile = window.innerWidth <= 768;
  }

  @Output() menuLinkClicked = new EventEmitter<any>();
  @Output() menuSelectionChanged = new EventEmitter<boolean>();
  @Output() profilePanelSelectionChanged = new EventEmitter<boolean>();
  @Output() mobileSidebarSelectionChanged = new EventEmitter<boolean>();
  @Output() sidebarCollapseSelectionChanged = new EventEmitter<boolean>();
  @Output() notificationsPanelSelectionChanged = new EventEmitter<boolean>();
  @Output() favPanelSelectionChanged = new EventEmitter<boolean>();
  @Output() feedbackFormToggleChanged = new EventEmitter<boolean>();
  @Output() preferencesSelectionPanelSelectionChanged = new EventEmitter<boolean>();

  attachOutsideOnClick = true;

  constructor(private router: Router) {
  }

  ngOnInit() {
    this.checkAndHideIfSidebarShouldBeHidden();

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.checkAndHideIfSidebarShouldBeHidden();
      }
    })
  }

  ngAfterContentInit(): void {
    if (this.title) {
      this.addTitleStyleTag(this.title);
    }
  }

  checkAndAddHolidayIcon(value: string) {
    this.holidayIconConfigs.forEach((config) => {
      const currentDate = new Date();
      if (currentDate > config.startDate && currentDate < config.endDate)
        value += ` ${config.addition}`
    })
    return value;
  }

  addTitleStyleTag(value: string) {
    const content = `#header_container #nav .nav_left .hamburger_logo .logo.intranet:after { content: '${this.checkAndAddHolidayIcon(value)}'}`;
    const styleTag = document.createElement("style");
    styleTag.className = "set_logo_content_value";
    styleTag.innerHTML = content
    const existing = document.head.getElementsByClassName("set_logo_content_value")[0];
    if (existing) existing.innerHTML = content;
    else document.head.appendChild(styleTag);
  }

  checkAndHideIfSidebarShouldBeHidden() {
    setTimeout(() => {
      if (window.location.href.indexOf('management-board-portal') > -1 && this.sidebarCollapsed == false) {
        console.log("hiding sidebar")
        this.collapseSidebarClick();
      }
    }, 5000);
  }

  hamburgerClick(event) {
    this.toggleMenu();
    event.stopPropagation();
  }

  searchIconClick() {
    this.router.navigate(['/search']);
  }

  navLinkClick(event, link, queryParams?:any, item?:any) {
    event.preventDefault();
    let qp = "?";
    for (let i = 0; i < Object.keys(queryParams).length; i++) {
      let key = Object.keys(queryParams)[i];
      let value = queryParams[key];
      qp += key + "=" + value + (i < Object.keys(queryParams).length ? "&" : "");
    }
    this.router.navigateByUrl(link + qp);
    this.menuLinkClicked.emit(item)
    this.toggleMenu();
  }

  toggleMenu() {
    if (this.profilePanelOpened)
      this.toggleControlPanel();
    if (this.mobileSidebarOpened)
      this.toggleMobileSidebar();
    if (this?.notificationsPanelOpened)
      this.toggleNotificationPanel();
    if (this.favPanelOpened)
      this.toggleFavPanel();

    this.hamburgerOpened = !this.hamburgerOpened;
    this.menuSelectionChanged.emit(this.hamburgerOpened);
  }

  logoClick(event) {
    this.router.navigateByUrl('/');
    let homeCategory = { badge: "fa-home", id: 0, index: 0, link: "/", name: "Home" };
    this.getMenuItemClasses(homeCategory);
    this.menuLinkClicked.emit(homeCategory)
  }

  profilePanelClick(event) {
    if (this.hamburgerOpened)
      this.toggleMenu();
    if (this.mobileSidebarOpened)
      this.toggleMobileSidebar();
    if (this.notificationsPanelOpened)
      this.toggleNotificationPanel();
    if (this.favPanelOpened)
      this.toggleFavPanel();

    this.toggleControlPanel();

    event.stopPropagation();
  }

  settingsAndPreferencesClick(event) {
    this.togglePreferencesSelectionPanel();

    event.stopPropagation();
  }
  mobileSidebarClick(event) {
    if (this.profilePanelOpened)
      this.toggleControlPanel();
    if (this.hamburgerOpened)
      this.toggleMenu();
    if (this.notificationsPanelOpened)
      this.toggleNotificationPanel();
    if (this.favPanelOpened)
      this.toggleFavPanel();

    this.toggleMobileSidebar();

    event.stopPropagation();
  }

  collapseSidebarClick() {
    this.sidebarCollapsed = !this.sidebarCollapsed;
    this.sidebarCollapseSelectionChanged.emit(this.sidebarCollapsed);
  }

  toggleControlPanel() {
    this.profilePanelOpened = !this.profilePanelOpened;
    this.profilePanelSelectionChanged.emit(this.profilePanelOpened);
  }

  toggleMobileSidebar() {
    this.mobileSidebarOpened = !this.mobileSidebarOpened;
    this.mobileSidebarSelectionChanged.emit(this.mobileSidebarOpened);
  }

  notificationsPanelClick(event) {
    if (this.hamburgerOpened)
      this.toggleMenu();
    if (this.profilePanelOpened)
      this.toggleControlPanel();

    this.toggleNotificationPanel();

    event.stopPropagation();
  }

  favouritesClick(event) {
    if (this.hamburgerOpened)
      this.toggleMenu();
    if (this.profilePanelOpened)
      this.toggleControlPanel();

    this.toggleFavPanel();

    event.stopPropagation();
  }

  toggleNotificationPanel() {
    this.notificationsPanelOpened = !this.notificationsPanelOpened;
    this.notificationsPanelSelectionChanged.emit(this.notificationsPanelOpened);
  }
  togglePreferencesSelectionPanel() {
    this.preferencesSelectionPanelOpened = !this.preferencesSelectionPanelOpened;
    this.preferencesSelectionPanelSelectionChanged.emit(this.preferencesSelectionPanelOpened);
  }

  toggleFavPanel() {
    this.favPanelOpened = !this.favPanelOpened;
    this.favPanelSelectionChanged.emit(this.favPanelOpened);
  }

  onClickedOutside(event) {
    if (this.hamburgerOpened) {
      this.toggleMenu();
    }
  }

  feedBackFormClick() {
    this.feedbackFormToggleChanged.emit(true);
  }

  getMenuItemClasses(item) {    
    const params = window.location.href.split('?').length > 1 ? window.location.href.split('?')[1] : "";
  
    if (item.name == 'Home' && window.location.pathname == '/')
      return 'current';
    else if (item.name == 'Apps' && ((window.location.href.indexOf('sustainability-barometer') != -1 || window.location.href.indexOf('powerbi-embedded') != -1) || params.indexOf(this.getRouterLabel(item.link.toLowerCase())) > -1) && window.location.href.indexOf(',') == -1)
      return 'current';
    else if (item.name == 'Communities' && (window.location.href.indexOf('/communities/internal') != -1 || params.indexOf(this.getRouterLabel(item.link.toLowerCase())) > -1) && window.location.href.indexOf(',') == -1)
      return 'current';
    else if (item.name == 'Documents' && (window.location.href.indexOf('/documents/internal') != -1 || params.indexOf(this.getRouterLabel(item.link.toLowerCase())) > -1) && window.location.href.indexOf(',') == -1)
      return 'current';
    else if (item.name == 'News' && (window.location.href.indexOf('/news/internal') != -1 || params.indexOf(this.getRouterLabel(item.link.toLowerCase())) > -1) && window.location.href.indexOf(',') == -1)
      return 'current';
    else if (item.name == 'People' && (window.location.href.indexOf('/people/internal') != -1 || params.indexOf(this.getRouterLabel(item.link.toLowerCase())) > -1) && window.location.href.indexOf(',') == -1)
      return 'current';
    else if (item.name == 'Projects' && (window.location.href.indexOf('/project/internal') != -1 || params.indexOf(this.getRouterLabel(item.link.toLowerCase())) > -1) && window.location.href.indexOf(',') == -1)
      return 'current';
    else if (item.name == 'Search' && window.location.pathname != '/'
      && window.location.href.indexOf(',') != -1
      //&& window.location.href.indexOf('cardType') == -1
      && window.location.href.indexOf('sustainability-barometer') == -1
      && window.location.href.indexOf('powerbi-embedded') == -1
      && window.location.href.indexOf('/communities/internal') == -1
      && window.location.href.indexOf('/documents/internal') == -1
      && window.location.href.indexOf('/news/internal') == -1
      && window.location.href.indexOf('/people/internal') == -1
      && window.location.href.indexOf('/project/internal') == -1)
      return 'current';
    else if (item.name == 'Search' && window.location.pathname == '/search' && window.location.href.indexOf('cardType') == -1)
      return 'current';

  }

  getRouterLabel(link) {
    switch (link) {
      case 'apps':
        return 'App'
      case 'communities':
        return "Community"
      case 'document':
        return "Document"
      case 'news':
        return "News"
      case 'people':
        return "People"
      case 'project':
        return "Project"

      default:
        break;
    }
  }

}
