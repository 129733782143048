import {
  AfterViewInit,
  Component,
  forwardRef,
  Injector,
  Input,
  OnInit
} from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseInputComponent } from '../base-input.directive';

@Component({
  selector: 'fpc-input-radio',
  templateUrl: './input-radio.component.html',
  styleUrls: ['./input-radio.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputRadioComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputRadioComponent),
      multi: true,
    }
  ],
})
export class InputRadioComponent extends BaseInputComponent implements OnInit, AfterViewInit {
  isRequired: boolean = false;

  @Input() name: string; 
  @Input('options') options;
  @Input() labelColor!: string;
  @Input() labelClass = 'mb-2';

  constructor(injector: Injector) {
    super(injector);
  }
  ngOnInit(): void {
    this.isRequired = this.formControl.getError('required');
  }
  ngAfterViewInit(): void {   
    this.control.valueChanges.subscribe((res)=>{
      this.formControl.setValue(res);
      this.formControl.markAsDirty();
      this.formControl.markAsTouched();
    })   
  }
}
