
 <div 
  class="fpc_link_card" 
  [ngStyle]="{'cursor': link?.length ? 'pointer' : 'default' }" 
  (click)="linkAction($event)">

      <div [ngClass]="{'center': linkPosition == 'center'} " class="fpc_link_card_body">
        <h3 *ngIf="header" class="fpc_link_card_header">
          {{ header }}
        </h3>
          <h4 *ngIf="subHeader" class="fpc_link_card_subheader">
            {{ subHeader }}
          </h4>
        
          <div *ngIf="content" [innerHtml]="content" class="bold"></div>
        </div>
      </div>