import { Component, Input, OnInit, Injector, forwardRef, ViewChild, ChangeDetectorRef, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, UntypedFormGroup, UntypedFormBuilder, NG_VALIDATORS } from '@angular/forms';
import { NgbCalendar,  NgbDate,  NgbDateParserFormatter,  NgbDateStruct,  NgbDatepicker,  NgbDatepickerNavigateEvent, NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { BaseInputComponent } from '../base-input.directive';
@Component({
  selector: 'fpc-input-date-picker',
  templateUrl: './input-date-picker.component.html',
  styleUrls: ['./input-date-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputDatePickerComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputDatePickerComponent),
      multi: true,
    }
  ],
})

export class InputDatePickerComponent  extends BaseInputComponent  implements OnInit, AfterViewInit {
  fromDate: NgbDate | null = null;
  toDate: NgbDate | null = null;
  hoveredDate: NgbDate | null = null;

  formFrom: UntypedFormGroup;
  formTo: UntypedFormGroup;

  _selectedDate: any;
  _maxDate!: NgbDate;
  _minDate!: NgbDate;

  months: string[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  current: NgbDate;
  currentMonthYear: string = '';
  today = this.calendar.getToday();
  backup: { fromDate: NgbDate; toDate: NgbDate };
  
  @Input() buttonTitle: string = '';
  @Input() dropDownButtonView: boolean = true;
  @Input() showDropDown: boolean = false;
  @Input() range: boolean = false; 
  @Input() calendarHeader: boolean = true;
  @Input() cardsRightSidebarWidget: any;
  @Input() closeWhenApply: boolean = false;
  @Input() events;

  @Input() set selectedDateRange(value: any) {
    if (!value || value == null || value == undefined) return;
    
    if(value){      
        this.fromDate = new NgbDate(
            value.fromDate.year,
            value.fromDate.month,
            value.fromDate.day
        );

        this.toDate = new NgbDate(
            value.toDate.year,
            value.toDate.month,
            value.toDate.day
        );

        this.formFrom = this.fb.group({
            rangeFromDay: [this.fromDate.day],
            rangeFromMonth: [this.fromDate.month],
            rangeFromYear: [this.fromDate.year],
          });
      
          
          this.formTo = this.fb.group({
            rangeToDay: [this.toDate.day],
            rangeToMonth: [this.toDate.month],
            rangeToYear: [this.toDate.year],
          });
    }
  }

  @Input() set selectedDate(value: any) {
    this.fromDate = this.parseDate(value);
    this.toDate = null;
  }

  @Input() set maxDate(value: any) {
    this._maxDate = this.parseDate(value);
  }

  @Input() set minDate(value: any) {
    this._minDate = this.parseDate(value);
  }

  private parseDate(value: any): NgbDate | null {
    const isoPattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(.\d{3})?Z?$/;
    if (!value) {
      console.log('Date value is null or undefined');
      return null; 
    }

    if (typeof value === 'object' && (value.year || value.month || value.day)) {
      console.log('Setting date from NgbDateStruct:', value);
      return new NgbDate(value.year, value.month, value.day);
    }

    if (typeof value === 'string' && isoPattern.test(value)) {
      const dateParts = value.split('T')[0].split('-');
      const year = parseInt(dateParts[0], 10);
      const month = parseInt(dateParts[1], 10);
      const day = parseInt(dateParts[2], 10);
      const ngbDateStruct: NgbDateStruct = { year, month, day };
      return NgbDate.from(ngbDateStruct);
    }

    return null; 
  }

  get maxDate(): NgbDate {
    return this._maxDate;
  }

  get minDate(): NgbDate {
    return this._minDate;
  }

  @Output() selectedDates: EventEmitter<NgbDate | NgbDate[]> = new EventEmitter<NgbDate | NgbDate[]>();

  @ViewChild('buttonDrop') buttonDrop: NgbDropdown;
  @ViewChild('dp') dp: NgbDatepicker;

  constructor(
    injector: Injector,
    private calendar: NgbCalendar,
    private fb: UntypedFormBuilder,
    private cdRef: ChangeDetectorRef,
    private ngbDateParserFormatter: NgbDateParserFormatter
  ) {
    super(injector);

    this.fromDate = this.calendar.getToday();
    this.toDate = this.calendar.getNext(this.calendar.getToday(), 'd', 10);
  }

  ngOnInit(): void {  
    if(this.range) {
        if(this.selectedDateRange){
            this.control.setValue(this.selectedDateRange);
            this.formControl.setValue(this.selectedDateRange);
        }    

        this.formFrom = this.fb.group({
            rangeFromDay: [this.fromDate.day],
            rangeFromMonth: [this.fromDate.month],
            rangeFromYear: [this.fromDate.year],
          });      
          
          this.formTo = this.fb.group({
            rangeToDay: [this.toDate.day],
            rangeToMonth: [this.toDate.month],
            rangeToYear: [this.toDate.year],
          });

    } else {
      
        if(this.selectedDate){
            this.formControl.setValue(this.selectedDate);
        }
        
    }
  }
  checkDropDown(event,dropdown){
    this.buttonDrop.open();
  }

  ngAfterViewInit(): void {
    this.cdRef.detectChanges();
  }
 
  isCurrent(date: NgbDate) {
    return date.equals(this.calendar.getToday());
  }

  isFocused(date: NgbDate) {
    return date.equals(this.fromDate);
  }

  next() {
    this.dp.navigateTo(this.calendar.getNext(this.dp.model.focusDate, 'm'));
  }

  previous() {
    this.dp.navigateTo(this.calendar.getPrev(this.dp.model.focusDate, 'm'));
  }

  onNavigate(event: NgbDatepickerNavigateEvent, dp) {
    if (!this.range) {
      let date: { year: number; month: number };

      date = event.next;

      setTimeout(() => {
        this.currentMonthYear = `${this.months[date.month - 1]} ${date.year}`;
      }, 0);

      this.dp = dp;
    } else {
      if (!this.dp) {
        this.dp = dp;
      }
      setTimeout(() => {
        this.currentMonthYear = `${
          this.months[this.dp.model.focusDate.month - 1]
        } ${this.dp.model.focusDate.year}`;
      }, 0);
    }
  }

  reset() {
    if (this.range) {
      this.fromDate = null;
      this.toDate = null;

      this.formFrom.get('rangeFromDay').setValue(null);
      this.formFrom.get('rangeFromMonth').setValue(null);
      this.formFrom.get('rangeFromYear').setValue(null);

      this.formTo.get('rangeToDay').setValue(null);
      this.formTo.get('rangeToMonth').setValue(null);
      this.formTo.get('rangeToYear').setValue(null);

      this.dp.navigateTo(this.calendar.getToday());
    } else {
      this.fromDate = this.calendar.getToday();
      this.dp.navigateTo(this.calendar.getToday());
    }

    this.formControl.setValue(null);

  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  onDateSelect(date: NgbDate) {
    if (this.range) {
        if (!this.fromDate && !this.toDate) {
            this.fromDate = date;

            this.formFrom.get('rangeFromDay').setValue(date.day);
            this.formFrom.get('rangeFromMonth').setValue(date.month);
            this.formFrom.get('rangeFromYear').setValue(date.year);
        } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
            this.toDate = date;

            this.formTo.get('rangeToDay').setValue(date.day);
            this.formTo.get('rangeToMonth').setValue(date.month);
            this.formTo.get('rangeToYear').setValue(date.year);
        } else {
            this.toDate = null;
            this.fromDate = date;

            this.formFrom.get('rangeFromDay').setValue(date.day);
            this.formFrom.get('rangeFromMonth').setValue(date.month);
            this.formFrom.get('rangeFromYear').setValue(date.year);

            this.formTo.get('rangeToDay').setValue(null);
            this.formTo.get('rangeToMonth').setValue(null);
            this.formTo.get('rangeToYear').setValue(null);
        }

    } else {
      this.fromDate = date;
      this.toDate = null;
      
      if(this.control){
        this.formControl.setValue(this.fromDate);        
      } else {
        this.selectedDates.emit(this.fromDate)
      }
  }

  }

  dayData = (date: NgbDate, current?: { year: number; month: number }): { hasEvents: boolean, isOutside: boolean, previousDayEvent: boolean } => {
    let data = { hasEvents: false, isOutside: false, selectedDate: this.fromDate, previousDayEvent: false };
    
    data.isOutside = current.month !== date.month;
    
    if (this.cardsRightSidebarWidget?.length == 0) return data;


    const dateString = `${date.day < 10 ? '0' + date.day : date.day}/${date.month < 10 ? '0' + date.month : date.month}/${date.year}`;
    
    if(this.cardsRightSidebarWidget?.length > 0){
      this.cardsRightSidebarWidget[0].sections = this.events;

      data.hasEvents = this.cardsRightSidebarWidget[0].sections.filter((section) => section.eventDays.indexOf(dateString) > -1).length > 0 && !data.isOutside;
    }
   

    data.previousDayEvent = this.isPreviousDay(date);

    return data;
  };



  isPreviousDay(date: NgbDate): boolean {
    const dateDate = new Date(`${date.month}/${date.day}/${date.year}`);
    const todayDate = new Date(`${this.today.month}/${this.today.day}/${this.today.year}`);
    return dateDate < todayDate;
  }

  apply() {
        if (!this.fromDate || !this.toDate) return;

        if (this.toFromDifFromBackup()) {
            this.backup = { fromDate: this.fromDate, toDate: this.toDate };
            if(this.control){
                this.formControl.setValue({
                    fromDate: this.fromDate,
                    toDate: this.toDate
                });
                this.control.setValue({
                    fromDate: this.fromDate,
                    toDate: this.toDate
                });                
            } else {
                this.selectedDates.emit([this.fromDate, this.toDate]) 
            }

        }
        this.cdRef.detectChanges()
  }

  toFromDifFromBackup() {
    if (!this.calendar.isValid(this.fromDate)) return false;
    if (!this.calendar.isValid(this.toDate)) return false;
    if (!this.fromDate.before(this.toDate)) return false;
    if (!this.backup || !this.fromDate || !this.toDate) return true;
    return (
      !this.fromDate.equals(this.backup.fromDate) ||
      !this.toDate.equals(this.backup.toDate)
    );
  }
}