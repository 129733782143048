import { Pipe, PipeTransform } from '@angular/core';
import { NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';

@Pipe({
    name: 'ngbDate'
})

export class NgbDateTranslatePipe implements PipeTransform {

    constructor(private i18n: NgbDatepickerI18n) { }
    transform(value: any): string {
        if (value) {
            return `${value.day} ${this.i18n.getMonthFullName(value.month)} ${value.year}`

        } else {
            return ''

        }
    }
}