import { Directive, EventEmitter, Input, Output } from '@angular/core';

export type SortDirection = 'asc' | 'desc' | '';
const rotate: { [key: string]: SortDirection } = { 'asc': 'desc', 'desc': 'asc', '': 'asc' };
export const compare = (v1, v2) => {
  if (typeof (v1) == "string" && typeof (v2) == "string") {
    v1 = v1.trim();
    v2 = v2.trim();
    if (v1.indexOf('/') > -1) return compareDates(v1, v2);
    else return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
  }
  else return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
};
export const compareDates = (v1, v2) => {
  let v1d = new Date(v1.split('/')[2], parseInt(v1.split('/')[1]) - 1, v1.split('/')[0]);
  let v2d = new Date(v2.split('/')[2], parseInt(v2.split('/')[1]) - 1, v2.split('/')[0]);
  return v1d < v2d ? -1 : v1d > v2d ? 1 : 0
};

export interface SortEvent {
  column: string;
  direction: SortDirection;
}

@Directive({
  selector: 'th[sortable]',
  host: {
    '[class.asc]': 'direction === "asc"',
    '[class.desc]': 'direction === "desc"',
    '(click)': 'rotate($event)'
  }
})
export class NgbSortableHeader {

  @Input() sortable!: string;
  @Input() direction: SortDirection = '';
  @Output() sort = new EventEmitter<SortEvent>();

  rotate(event) {
    //console.log(event.target.tagName)
    if (!event.target.closest("div").classList.contains("sort")) return;
    if (!this.sortable) return;
    this.direction = rotate[this.direction];
    this.sort.emit({ column: this.sortable, direction: this.direction });
  }
}