import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchHeader } from './_directives/search-header.directive';
import { NgbSortableHeader } from './_directives/ngb-sortable-header.directive';
import { FilterPipe } from './_pipes/filter.pipe';
import { FPCommonModule } from '../global/fpcommon.module';


@NgModule({
  declarations: [
    SearchHeader,
    NgbSortableHeader,
    FilterPipe
  ],
  imports: [
    CommonModule,
    FPCommonModule
  ], 
  exports: [
    SearchHeader,
    NgbSortableHeader,
    FilterPipe
  ]
})
export class SmartSharedModule { }
