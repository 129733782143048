<fpc-input-field-label
    [label]="_label" 
    [labelAdditionalInfo]="_labelAdditionalInfo" 
    [required]="required"
    [popoverText]="_popoverText" 
    [popoverTitle]="_popoverTitle"
    [labelClass]="'fw-bold mb-0 mb-2 text-gray-base text-truncate'">
        
    <div class="image-uploader-container">
            <div id="image-uploader" class="base-status">
                <div *ngIf="!picFile" class="drop-area-container m-0 gray-lighter-bg" style="cursor: pointer;">
                    <div class="drop-area m-0 d-flex flex-column justify-content-center align-items-center border-gray" (click)="fileAreaClick()">
                        <input 
                            id="fileInput" 
                            class="image-picker"
                            (change)="fileInputChange($event)"
                            type="file"                                        
                            accept="image/png, image/jpeg, image/jpg" 
                            hidden
                            [required]="required"
                        >
                        <h4 class="fw-bold">Drag and Drop File here</h4>
                        <h4 class="gray-dark mb-3 fw-bold">(PNG, JPG and JPEG only)</h4>
                        <fpc-button [text]="'Browse files'" [buttonColorClass]="'outline-primary'"></fpc-button>
                    </div>
                </div>

                <div *ngIf="picFile" class="image-div">
                    <img class="img-thumbnail mb-3" [src]="picFile">
                    <br />
                    <fpc-button 
                        [text]="'Remove'" 
                        [buttonColorClass]="'outline-danger'"
                        (buttonClick)="picFile = undefined;">
                    </fpc-button>
                </div>
            </div>
        </div>
     
    <p class="h6 text-red mt-2" *ngIf="fileErrorMessage">{{fileErrorMessage}}</p>
</fpc-input-field-label>


<fpc-input-error [formControl]="control" [customErrors]="_customErrors"></fpc-input-error>