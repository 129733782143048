import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, AfterContentInit } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'fpc-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent {
  @ViewChild(NgbDropdown) public buttonDrop: NgbDropdown;
  @ViewChild('ddm') private dropdownMenu: ElementRef<HTMLElement>;

  @Input() id: string;
  @Input() buttonColorClass: string = 'outline-primary';
  @Input() buttonClass: string;
  @Input() buttonText: string;
  @Input() placeholderText: string;
  @Input() buttonActive: boolean = false;
  @Input() iconLayout: boolean = false;
  @Input() buttonMenuPlacement: string | string[];
  @Input() iconSize: string;
  @Input() iconClass: string;
  @Input() disabled: boolean;
  @Input() customNgClass: any;

  @Input() filterOption: boolean = false;
  @Input() menuClass: string='';
  @Input() autoclose: boolean = true;
  @Input() closeOnApply: boolean = true;
  @Input() container: string = 'null';

  @Output() buttonClick = new EventEmitter<any>();
  @Output() isOpened = new EventEmitter<any>();

  onButtonClick(e) {
    this.buttonClick.emit(e)
  }

  setupApplyButtonAutoClose() {
    let applyBtn: HTMLButtonElement;
    for (let i = 0; i < this.dropdownMenu?.nativeElement?.getElementsByClassName("apply")?.length; i++) {
      const btn = this.dropdownMenu?.nativeElement?.getElementsByClassName("apply")?.item(i);
      //@ts-ignore
      applyBtn = btn;
    }
    if (!applyBtn) return;
    applyBtn.addEventListener('click', (event: Event) => {
      this.buttonDrop.close();
    })
  }

  isDropdownOpen(e) {
    this.isOpened.emit(e);
    //if (e) setTimeout(() => this.setupApplyButtonAutoClose(), 30);
  }

}
