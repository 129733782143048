import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import { FeedbackFormResponse } from "../_models/feedback/feedbackFormResponse";

export const APP_SERVICE = new InjectionToken<AppService>("AppService");

export interface AppService {
  getFeedBackForm(): Observable<any>;
  postUserFeedBack(feedbackFormResponse: FeedbackFormResponse): Observable<any>;
  downloadFile(title: string, url:string): void;
}
export class MockAppService {}