<div class="input_container">
  <div *ngIf="searchBar" class="p-4 border-bottom">
    <fpc-input-text 
      [label]="_label"
      [formControl]="inputTextFormControl" 
      [placeholder]="searchPlaceholder" 
      [inputClass]="'search'"
      class="mb-3"
    ></fpc-input-text>
    
    <div class="d-flex pt-3 gap-3" *ngIf="multiSelect && areResultsFound">
      <fpc-button 
        [buttonClass]="'w-100'" 
        [buttonColorClass]="'outline-primary'" 
        [text]="'Select all'" 
        (buttonClick)="selectAll()" 
        class="flex-fill"
        [disabled]="allSelected"
      ></fpc-button>

      <fpc-button 
        [buttonClass]="'w-100'"
        [buttonColorClass]="'outline-primary'" 
        [text]="'Deselect all'" 
        (buttonClick)="deSelectAll()" 
        class="flex-fill"
        [disabled]="searchOptionSelected.length == 0">
      </fpc-button>
    </div>
  </div>

  <div class="search_result_container">
    <div class="search_result" ngbDropdownItem
      *ngFor="let result of filteredList; let i = index;" 
      (click)="optionSelected(i, result)" 
      [ngClass]="[ indexArray.includes(i) ? 'active': '', multiSelect ? '': 'dropdown-item', result.disabled? 'disabled':'']">
        <h5 class="mb-0 fw-bold text-truncate">{{result.description}}</h5>
    </div>

    <div class="search_result pe-none" *ngIf="(!areResultsFound && !warningMessageExist) || (warningMessageExist  && warningMessage?.length > 0)">
      <h6 class="mb-0 fw-bold text-gray-base">{{!areResultsFound && !warningMessageExist ? 'No Results found.': warningMessage}}</h6>
    </div>
  </div>
  
  <div class="p-4 border-top apply" *ngIf="areResultsFound && multiSelect">
    <fpc-button 
    [buttonClass]="'w-100'"
    [buttonColorClass]="'outline-primary'" 
    [text]="applyButtonText" 
    (buttonClick)="apply()"
    [disabled]="searchOptionSelected.length == 0"></fpc-button>
  </div>
</div>