import { Component, Input, OnInit } from '@angular/core';
import { AccordionPanel } from '../../shared/_models/accordion.model';

@Component({
  selector: 'fpc-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss']
})
export class SectionComponent implements OnInit {
  disabled = false;
  isCollapsed = false;

  @Input() accordionPannel!: AccordionPanel;

  constructor() { }

  ngOnInit(): void {
  }

}
