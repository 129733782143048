import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'fpc-fpcommon',
  template: '',
  styleUrls: ['./fpcommon.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FpcommonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
