export class ButtonColor {
    public static TRANSPARENT: "transparent";
    public static TURQUOISE: "turquoise";
    public static WHITE: "white";
    public static RED: "red";
    public static GREY: "grey";
    public static YELLOW: "yellow";
    public static GREY_DARKER: "grey-darker";
    public static GREY_LIGHT: "grey-light";
    public static DARK_GREY: "grey-dark";
}