import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AIChatBotComponent } from './ai-chat-bot.component';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from '../../../presentational/components/buttons/button/button.module';
import { InputTextareaModule } from '../../../presentational/components/form-elements/input-textarea/input-textarea.module';
import { AccordionModule } from '../../../presentational/components/accordion/accordion.module';

@NgModule({
  declarations: [
    AIChatBotComponent
  ],
  imports: [
    HttpClientModule,
    CommonModule,
    ButtonModule,
    ReactiveFormsModule,
    InputTextareaModule,
    AccordionModule
  ],
  exports: [
    AIChatBotComponent
  ]
})
export class AiChatBotModule { }
