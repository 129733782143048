import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { WidgetContainerComponent } from './widget-container.component';
import { ButtonModule } from '../buttons/button/button.module';
import { PresentationalSharedModule } from '../../shared/presentational-shared.module';

@NgModule({
  declarations: [
    WidgetContainerComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    ButtonModule,
    PresentationalSharedModule
  ], 
  exports: [
    WidgetContainerComponent
  ]
})
export class WidgetContainerModule { }
