import { Component, Input } from '@angular/core';

@Component({
  selector: 'fpc-single-image',
  templateUrl: './single-image.component.html',
  styleUrls: ['./single-image.component.scss']
})

 
export class SingleImageComponent {
  
  showModal: boolean = false;  

  @Input() imgWidth: string = '';
  @Input() imgHeight: string = '';
  @Input() path!: string;
  @Input() zoom: boolean = true;

  showImgModal(){
    this.showModal = !this.showModal;    
  }

}