import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbModel } from '../../shared/_models';

@Component({
  selector: 'fpc-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  @Input() showBreadcrumbs: boolean = false;
  @Input() breadcrumbs!: BreadcrumbModel[];

  constructor(public route: ActivatedRoute) { }

  ngOnInit(): void {
  }


}
