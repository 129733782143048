import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SingleImageComponent } from './single-image.component';
import { ButtonModule } from '../buttons/button/button.module';



@NgModule({
  declarations: [
    SingleImageComponent
  ],
  imports: [
    CommonModule,
    ButtonModule
  ], 
  exports: [
    SingleImageComponent
  ]
})
export class SingleImageModule { }
