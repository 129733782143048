import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputRadioComponent } from './input-radio.component';
import { InputFieldLabelModule } from '../input-field-label/input-field-label.module';
import { InputErrorModule } from '../input-error/input-error.module';

@NgModule({
  declarations: [InputRadioComponent],
  imports: [CommonModule, NgbModule, FormsModule, ReactiveFormsModule, InputFieldLabelModule, InputErrorModule],
  exports: [InputRadioComponent],
})
export class InputRadioModule {}
