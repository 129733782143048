import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LegendComponent } from '../legend/legend.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from '../../../presentational/components/buttons/button/button.module';
import { InputTextModule } from '../../../presentational/components/form-elements/input-text/input-text.module';

@NgModule({
  declarations: [
    LegendComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    InputTextModule
  ],
  exports: [
    LegendComponent
  ]
})
export class LegendModule { }
