import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from '../../buttons/button/button.module';
import { InputDatePickerComponent } from './input-date-picker.component';
import { InputFieldLabelModule } from '../input-field-label/input-field-label.module';
import { DropdownModule } from '../../dropdown/dropdown.module';

@NgModule({
  declarations: [InputDatePickerComponent],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ButtonModule,
    ReactiveFormsModule,
    InputFieldLabelModule,
    NgbDropdownModule,
    DropdownModule
  ],
  exports: [InputDatePickerComponent],
})
export class InputDatePickerModule { }
