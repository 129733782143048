import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbCollapseModule  } from '@ng-bootstrap/ng-bootstrap';
import { CollapseComponent } from './collapse.component';
import { ButtonModule } from '../buttons/button/button.module';

@NgModule({
  declarations: [CollapseComponent],
  imports: [ CommonModule, NgbCollapseModule, ButtonModule ],
  exports: [CollapseComponent]
})
export class CollapseModule {}
