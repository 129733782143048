import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AiChatBot } from '../../shared/_models/ai-chat-bot.model';
import { AiChatBotService } from '../../shared/_services';
import { USER_SERVICE, UserService } from '../../shared/_services/user.service';
import { APP_SERVICE, AppService } from '../../shared/_services/app.service';

@Component({
  selector: 'fpc-aichat-bot',
  templateUrl: './ai-chat-bot.component.html',
  styleUrls: ['./ai-chat-bot.component.scss']
})
export class AIChatBotComponent implements OnInit {

  @Input() userProfilePic: string = 'https://designwebsite.fosterandpartners.com/images/people/User_00.jpg';

  sendMessageSub$: any;

  showPanel: boolean = false;

  messageForm: FormGroup;

  isResponseLoaded: boolean = false;

  messages: AiChatBot[] = [];

  defaultMessage: AiChatBot = {
    sender: 'fp',
    avatar: 'https://designassets.fosterandpartners.com/assets/images/favicon/plus-sharp-regular.png',
    message: `Hello! Welcome to Foster + Partners advanced search. Ask me about BIM documents, for example 'How is glass used in buildings?'
    `
  };

  userHasPermission: boolean;

  constructor(
    @Inject(USER_SERVICE) readonly userService: UserService,
    @Inject(APP_SERVICE) readonly appService: AppService,
    private formBuilder: FormBuilder,
    private aichatbotService: AiChatBotService) {
    
      if (Object.values(this.userService).length > 0){
        if (this.userService.userInformation) {
          this.setVisible(this.userService.userInformation);
        } else {
          this.userService.userInformationReadyEvent.subscribe((userInfo) => {
            this.setVisible(userInfo);
          });
        }
      } else {
        this.userHasPermission = true;
      }
    

    this.setInitialMessage(this.messages);
  }

  ngOnInit(): void {
    this.messageForm = this.formBuilder.group({
      message: [null, [Validators.required]]
    });
  }

  setVisible(userInfo): void {
    // Nad Martin Megan
    this.userHasPermission = (userInfo.studio == "Information Systems" || userInfo.id == 7788 || userInfo.id == 6392 || userInfo.id == 7069);
  }

  setInitialMessage(data): void {
    this.messages = this.messages.length == 0 ? [this.defaultMessage, ...this.messages] : data;
  }

  onSubmit(e?: KeyboardEvent): void {
    this.isResponseLoaded = true;

    this.messages.unshift(
      {
        "sender": 'user',
        "message": this.messageForm.value.message,
        "avatar": this.userProfilePic
      }
    );

    const messageValue: string = this.messageForm.value.message;
    this.messageForm.get('message').setValue(null);

    if (e) {
      e.preventDefault();
      (e.target as HTMLTextAreaElement).value = null;
    }

    const isDocumentation = Object.keys(this.userService).length <= 0;

    isDocumentation ?  this.setMockMessages() : this.setMessages(messageValue);
  }

  setMockMessages(){
    this.isResponseLoaded = false;    
     
    this.messages.unshift(
      {
        "sender": 'fp',
        "message":"I'm currently searching for the information you requested.",
        "avatar": 'https://designassets.fosterandpartners.com/assets/images/favicon/plus-sharp-regular.png'
      }
    ); 
  }

  setMessages(messageValue){
    this.sendMessageSub$ = this.aichatbotService.sendMessage(messageValue).subscribe((res) => {

      this.isResponseLoaded = false;

      const documents = res.documents.map((doc, index) => {
        return {
          id: index,            
          url: doc.url,
          filepath: doc.filepath,
          title: doc.title,            
          active: true,
        };
      });

      this.messages.unshift(
        {
          "sender": 'fp',
          "message": res.message,
          "documents": documents,
          "avatar": 'https://designassets.fosterandpartners.com/assets/images/favicon/plus-sharp-regular.png'
        }
      );
    });      
  }

  togglePanel(): void {
    this.showPanel = !this.showPanel;
  }

  reset(): void {
    this.sendMessageSub$.unsubscribe();
    this.isResponseLoaded = false;
    this.messages = [];
    this.messages.push(this.defaultMessage);
  }

  download(title: string, url: string): void {
    const urlSplit = title.split('/');
    this.appService.downloadFile(urlSplit[urlSplit.length - 1].split('.')[0], url);
  }
}
