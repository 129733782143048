import { Component, Input, OnInit } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

export interface LinkStructure { title: string; routerLink: string; }
@Component({
  selector: 'app-showcase-sidenav',
  templateUrl: './showcase-sidenav.component.html',
  styleUrls: ['./showcase-sidenav.component.scss']
})
export class ShowcaseSidenavComponent implements OnInit {

  dataTarget: string;
  matched = this.observer.isMatched('(max-width: 700px)');
  fpComponentsLinks: LinkStructure[] = [];
  fpComponentsFormlinks: LinkStructure[] = [];
  fpCommonLinks: LinkStructure[] = [];
  fpReadmeLinks: LinkStructure[] = [];

  @Input() isHome: boolean = false;


  constructor(private observer: BreakpointObserver) { }

  ngOnInit(): void {
    this.fpComponentsLinks = [
      {
        title: 'Ai chat-bot',
        routerLink: '/core/basic/components/ai-chat-bot'
      },
      {
        title: 'Accordion',
        routerLink: '/core/basic/components/accordion'
      },
      {
        title: 'Button',
        routerLink: '/core/basic/components/button'
      },
      {
        title: 'Breadcrumb',
        routerLink: '/core/basic/components/breadcrumb'
      },
      {
        title: 'Category button',
        routerLink: '/core/basic/components/category-button'
      },
      {
        title: 'Collapse',
        routerLink: '/core/basic/components/collapse'
      },
      {
        title: 'Dropdown',
        routerLink: '/core/basic/components/dropdown'
      },
      {
        title: 'Flag',
        routerLink: '/core/basic/components/flag'
      },
      {
        title: 'Footer',
        routerLink: '/core/basic/components/footer'
      },
      {
        title: 'Link card',
        routerLink: '/core/basic/components/link-card'
      },
      {
        title: 'Link list',
        routerLink: '/core/basic/components/link-list'
      },
      {
        title: 'Message container',
        routerLink: '/core/basic/components/message-container'
      },
      {
        title: 'Order by button',
        routerLink: '/core/basic/components/order-by'
      },
      {
        title: 'Preloader',
        routerLink: '/core/basic/components/preloader'
      },
      {
        title: 'Reactions',
        routerLink: '/core/basic/components/reactions'
      },
      {
        title: 'Section',
        routerLink: '/core/basic/components/section'
      },
      {
        title: 'Single image',
        routerLink: '/core/basic/components/single-image'
      },
      {
        title: 'Sortable list',
        routerLink: '/core/basic/components/sortable-list'
      },
      {
        title: 'Step',
        routerLink: '/core/basic/components/stepp'
      },
      {
        title: 'Widget container',
        routerLink: '/core/basic/components/widget-container'
      }
    ];

    this.fpComponentsFormlinks = [
      {
        title: 'Image uploader',
        routerLink: '/core/basic/form-elements/image-uploader'
      },        
      {
        title: 'Input check-box',
        routerLink: '/core/basic/form-elements/input-check-box'
      },
      {
        title: 'Input date',
        routerLink: '/core/basic/form-elements/input-date'
      },   
      {
        title: 'Input number',
        routerLink: '/core/basic/form-elements/input-number'
      },   
      {
        title: 'Input radio',
        routerLink: '/core/basic/form-elements/input-radio'
      },
      {
        title: 'Input search',
        routerLink: '/core/basic/form-elements/input-search'
      },
      {
        title: 'Input select',
        routerLink: '/core/basic/form-elements/input-select'
      },
      {
        title: 'Input text',
        routerLink: '/core/basic/form-elements/input-text'
      },
      {
        title: 'Input textarea',
        routerLink: '/core/basic/form-elements/input-textarea'
      },
      {
        title: 'Input toggle',
        routerLink: '/core/basic/form-elements/input-toggle'
      },
    ];

    this.fpCommonLinks = [
      {
        title: 'Navbar',
        routerLink: '/core/functional/navbar'
      },
      {
        title: 'Legend',
        routerLink: '/core/functional/legend'
      }
    ];

    this.fpReadmeLinks = [
      {
        title: 'Setup',
        routerLink: '/core/read-me/setup'
      },
      {
        title: 'Assets',
        routerLink: '/core/read-me/image-css'
      }
    ];
  }

  setDataTarget(a) {
    this.observer
      .observe(['(max-width: 700px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.dataTarget = '#nav-wrapper';

        } else {
          this.dataTarget = null;
        }
      });
  }


}
