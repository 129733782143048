<div class="input-container">
    <!-- Header -->

    <div class="header" *ngIf="range">
      <div class="calendar-dates">
        <div class="d-flex date-from mb-2">
          <h4 class="mr-3 mb-0 fw-bold">
            <label for="range-from-day">From:</label>
          </h4>

          <form [formGroup]="formFrom" class="d-flex">
            <input type="number" class="form-control" [formControlName]="'rangeFromDay'" id="range-from-day" placeholder="DD" [min]="1" [max]="31">
            <input type="number" class="form-control" [formControlName]="'rangeFromMonth'" id="range-from-month" placeholder="MM" [min]="1" [max]="12">
            <input type="number" class="form-control" [formControlName]="'rangeFromYear'" id="range-from-year" placeholder="YYYY" [min]="1700" [max]="2999">
          </form>

        </div>
        <div class="d-flex date-to">
          <h4 class="mr-3">
            <label for="range-to-day">To </label>
          </h4>

          <form [formGroup]="formTo" class="d-flex">
            <input type="number" class="form-control" [formControlName]="'rangeToDay'" id="range-to-day" placeholder="DD" [min]="1" [max]="31">
            <input type="number" class="form-control" [formControlName]="'rangeToMonth'" id="range-to-month" placeholder="MM" [min]="1" [max]="12">
            <input type="number" class="form-control" [formControlName]="'rangeToYear'" id="range-to-year" placeholder="YYYY" [min]="1700" [max]="2999">
          </form>


        </div>
      </div>
    </div>


    <div class="date-tab d-block">

      <!-- Content -->
      <div class="calendar">
        <div class="calendarHeader" *ngIf="calendarHeader">
          <div class="d-flex align-items-center justify-content-between">
            <div class="title mb-2 d-flex align-items-center gap-3">
              <i class="fal fa-calendar-alt text-turquoise"></i>
              <h4 class="mb-0 fw-bold">{{currentMonthYear}}</h4>
            </div>
            <div class="controls d-flex align-items-center mb-2">
              <div class="current-day d-flex align-items-center justify-content-center">
                <fpc-button [buttonLayoutType]="'icon'" [buttonColorClass]="'primary'" [title]="'Reset'" (buttonClick)="reset()"
                  [customNgStyle]="{'font-size.rem': 1.6 ,'font-weight': 300, 'border' : 'solid 0px #e5e5e5' }" [iconClass]="'fal fa-undo'"></fpc-button>
                <!-- <i class="fal fa-undo"></i> -->
              </div>
              <div class="slick-control-prev arrow_left d-flex align-items-center justify-content-center">
                <fpc-button [buttonLayoutType]="'icon'" [buttonColorClass]="'primary'" [title]="'Previous month'" (buttonClick)="previous()"
                  [customNgStyle]="{'font-size.rem': 1.6 ,'font-weight': 300, 'border' : 'solid 0px #e5e5e5' }" [iconClass]="'fal fa-arrow-left'"></fpc-button>
              </div>
              <div class="slick-control-next arrow_right d-flex align-items-center justify-content-center">
                <fpc-button [buttonLayoutType]="'icon'" [title]="'Next month'" [buttonColorClass]="'primary'" (buttonClick)="next()"
                  [customNgStyle]="{'font-size.rem': 1.6 ,'font-weight': 300, 'border' : 'solid 0px #e5e5e5' }" [iconClass]="'fal fa-arrow-right'"></fpc-button>
              </div>
            </div>
          </div>

        </div>

        <!-- Calendar Table -->
        <div class="calendar-table calendar-container">
          <ngb-datepicker [minDate]="minDate" [maxDate]="maxDate" class="custom-date-picker" style="margin-bottom: 0; border-radius: 0;" #dp outsideDays="visible" [weekdays]="false"
            (navigate)="onNavigate($event, dp)" (dateSelect)="onDateSelect($event)" [dayTemplate]="t" [dayTemplateData]="dayData" navigation="none">
            <ng-template ngbDatepickerContent>
              <div *ngFor="let month of dp.state.months">
                <div class="ngb-datepicker-month">
                  <div class="ngb-dp-week ngb-dp-weekdays">
                    <div class="ngb-dp-weekday d-flex align-items-center"><span>M</span></div>
                    <div class="ngb-dp-weekday d-flex align-items-center"><span>T</span></div>
                    <div class="ngb-dp-weekday d-flex align-items-center"><span>W</span></div>
                    <div class="ngb-dp-weekday d-flex align-items-center"><span>T</span></div>
                    <div class="ngb-dp-weekday d-flex align-items-center"><span>F</span></div>
                    <div class="ngb-dp-weekday d-flex align-items-center"><span>S</span></div>
                    <div class="ngb-dp-weekday d-flex align-items-center"><span>S</span></div>
                  </div>
                </div>
                <ngb-datepicker-month class="ngb-datepicker-table" [month]="month"></ngb-datepicker-month>
              </div>
            </ng-template>
          </ngb-datepicker>
        </div>
        <!-- X Calendar Table -->
      </div>
      <!-- X Content -->

      <!-- Footer -->
      <div *ngIf="range" class="date_footer">
        <div class="footer_buttons">
          <fpc-button [buttonColorClass]="'outline-primary'" [buttonClass]="'apply w-100'" [text]="'Apply'" (buttonClick)="apply()"
            [ngClass]="[!toFromDifFromBackup() ? 'pe-none' : '']" [disabled]="!toFromDifFromBackup()"></fpc-button>
        </div>
      </div>
      <!-- X Footer -->

      <ng-template #t let-date let-focused="focused" let-data="data">

        <div class="custom-day justify-content-center" [class.focused]="isFocused(date)" [class.range]="range ? isRange(date) : null"
          [class.faded]="range ? (isHovered(date) || isInside(date)) : null" [class.current]="isCurrent(date)" (mouseenter)="hoveredDate = date"
          (mouseleave)="hoveredDate = null" [ngStyle]="{ background: data.isOutside ? 'transparent' : '', cursor: data.isOutside ? 'default' : 'pointer' }"
          [ngClass]="{ 'events': data.hasEvents, 'inactive': data.isOutside, 'dropdown-item': !range }">
          <span> {{ date.day }} </span>

        </div>
      </ng-template>
    </div>
  </div>