<div class="row">
  <div class="col-20">
    <app-showcase-sidenav [isHome]="true"></app-showcase-sidenav>
  </div>
  <div class="col-20">
    <div class="home-container">
      <div class="container">
        <h1 class="my-2">Design Assets</h1>
        <h2 class="mb-5">This library is intended as a tool to help developers build custom components with common interaction patterns, bringing a unified user
          experience across various platforms.</h2>
      </div>
    </div>
  </div>
  <div class="col-20" style="padding: 0 2rem;">
    <div class="container">

      <div class="card-container">
        <div class="card" routerLink="/core">
          <div class="card-body">
            <div class="card-text">
              <div class="mb-3">
                <i class="card-img-fp fa-regular fa-layer-group fa-2x"></i>
                <h2 class="card-title">Components</h2>
              </div>
              The page contains the library documentation. For each component there is an overview, api and examples. The components are split into two categories:
              smart and presentational.
            </div>
          </div>
        </div>

        <div class="card" routerLink="/core/read-me">
          <div class="card-body">
            <div class="card-text">
              <div class="mb-3">
                <i class="card-img-fp fa-regular fa-file-lines fa-2x"></i>
                <h2 class="card-title">Read me</h2>
              </div>
              This section provides guidelines for using the library. It includes instructions for installation and permission setup, links to image assets and other
              resources.
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>