import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ButtonModule } from '../buttons/button/button.module';
import { DropdownModule } from '../dropdown/dropdown.module';
import { ReactionsComponent } from './reactions.component';

@NgModule({
  declarations: [
    ReactionsComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    ButtonModule,
    DropdownModule
  ],
  exports: [
    ReactionsComponent
  ]
})
export class ReactionsModule { }
