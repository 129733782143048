import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CopyPreDirective } from './directives/copy-pre.directive';
import { CommonModule } from '@angular/common';
import { ClipboardModule } from 'ngx-clipboard';
import { SharedModule } from './shared/shared.module';
import { LayoutModule } from '@angular/cdk/layout';
import { HomeComponent } from './components/home/home.component';
import { RouterModule } from '@angular/router';
import { SingleImageModule } from 'projects/fpcommon/src/lib/presentational/components/single-image/single-image.module';
import { ButtonModule } from 'projects/fpcommon/src/lib/presentational/components/buttons/button/button.module';
import { FPCommonModule } from '../../projects/fpcommon/src/lib/smart/global/fpcommon.module';
import { CONFIG_LOADER_SERVICE, MockConfigLoaderService, USER_SERVICE, MockUserService, APP_SERVICE, MockAppService } from 'projects/fpcommon/src/public-api';
@NgModule({
  declarations: [
    AppComponent,
    CopyPreDirective,
    HomeComponent
  ],
  imports: [
    NgbModule,
    CommonModule,
    BrowserModule,
    RouterModule,
    ClipboardModule,
    AppRoutingModule,
    SingleImageModule,
    SharedModule,
    LayoutModule,
    ButtonModule,
    FPCommonModule
  ],
  providers: [
    { provide: CONFIG_LOADER_SERVICE, useClass: MockConfigLoaderService },
    { provide: USER_SERVICE, useClass: MockUserService },
    { provide: APP_SERVICE, useClass: MockAppService }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
