import { enableProdMode } from "@angular/core"
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic"
import { forkJoin } from "rxjs"

export const fetchConfigsAndBootstrapApp = (APP_CONFIG, environment, AppModule) => {
  function fetchData(url: string) {
    return new Promise((resolve, reject) => {
      fetch(url)
        .then((response) => resolve(response.json()))
        .catch(() => reject())
    })
  }

  forkJoin([
    window.location.hostname == 'localhost'
      ? fetchData('assets/config-local.json').catch(e => null)
      : new Promise((resolve) => resolve(null)),
    fetchData('assets/config.json?v=2').catch(e => null)
  ]).toPromise()
    .then((results) => {
      const config = (results[0] ? Object.assign(results[1], results[0]) : results[1])

      if (environment.production) {
        enableProdMode();
      }

      platformBrowserDynamic([{ provide: APP_CONFIG, useValue: config }]).bootstrapModule(AppModule)
        .catch(err => console.error(err));
    })
}