import {
  Component,
  forwardRef,
  Injector,
  Input,
  AfterViewInit,
  ChangeDetectorRef,
  EventEmitter,
  Output,
  OnInit,
} from '@angular/core';
import { FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SearchResult } from '../../../shared/_models/search.result.model';
import { BaseInputComponent } from '../base-input.directive';
import { switchMap, map, tap, mergeMap, distinctUntilChanged, concatMap } from 'rxjs/operators';
import { of, Observable, Subscriber, concat, iif, from } from 'rxjs';

@Component({
  selector: 'fpc-input-search',
  templateUrl: './input-search.component.html',
  styleUrls: ['./input-search.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputSearchComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputSearchComponent),
      multi: true,
    }
  ],
})
export class InputSearchComponent extends BaseInputComponent implements OnInit, AfterViewInit {

  inputTextFormControl = new FormControl(null);   

  areMinimumCharactersTyped: boolean = false;
  areResultsFound: boolean = false;
  warningMessageExist: boolean = false; 

  filteredList: SearchResult[] = [];
  active: number = null;
  indexArray: number[] = [];
  searchOptionSelected: any = [];
  allSelected: boolean = false;

  @Input() searchBar: boolean = true;
  @Input() labelColor: string;
  @Input() isFormValid: boolean = true;
  @Input() lengthSearch: number = 0;  
  @Input() searchPlaceholder: string = '';  
  @Input() startWithFullList: boolean = false;
  @Input() warningMessage: string = '';
  @Input() inputSize: string;
  @Input() multiSelect: boolean = false;
  @Input() applyButtonText: string = 'Apply';
  @Input() preselectedElements: SearchResult[] = [];
  
  _searchList: any[] = [];
  @Input() set searchList(value: any[]){
    this._searchList = value;
    this.newListSubscriber?.next(value);
  };

  get searchList(){
    return this._searchList;
  } 
  
  newSearchList$: Observable<SearchResult[]>; 
  newListSubscriber: Subscriber<SearchResult[]>;
  
  @Input() searchList$: Observable<SearchResult[]>;

  @Output() selectedValues: EventEmitter<string[]> = new EventEmitter();

  constructor(injector: Injector, private cdRef: ChangeDetectorRef) {
    super(injector);
  }

  ngOnInit(): void {
    if (this.warningMessage?.length) this.warningMessageExist = true;        
   
    this.newSearchList$ = !this.searchList$ ? new Observable((sub) => {
      this.newListSubscriber = sub
      sub.next(this.searchList)
    }) : this.searchList$;
    
    if (this.startWithFullList) {     
      

      this.newSearchList$.subscribe((searchResult: SearchResult[]) => {
        
        this.areResultsFound = searchResult.length > 0;
        this.warningMessageExist = false;
        this.areMinimumCharactersTyped = true;
        this.filteredList = searchResult;
      });
    }
    if(this.preselectedElements?.length > 0){
      let ids = this.preselectedElements.map(obj => this.filteredList.findIndex(item => item.id == obj.id));      
      this.preselect(ids);
    }  
  } 


  ngAfterViewInit() {           
    this.inputTextFormControl.valueChanges.pipe().subscribe((value) => {
      of(value).pipe(
        switchMap((searchString: string) => {
          return this.newSearchList$.pipe(          
            map((results: SearchResult[]) => {
              searchString = (Array.isArray(searchString)) ? searchString[0] : searchString;
              if (searchString?.length > 0) {                
                let filteredRes = results.filter((filter) => {
                  if(this.allSelected) 
                    this.indexArray.push(filter.id)
                  return filter.description.toLowerCase().includes(searchString?.toLowerCase());
                });
                return filteredRes;

              } else {
                this.deSelectAll();
                return this.startWithFullList ? results : []
                         
              }
            }),
            tap((searchRes:SearchResult[]) => {
              if (this.warningMessage?.length) {
                if (searchString.length > this.lengthSearch || this.startWithFullList) {
                  this.warningMessageExist = false;
                  this.areMinimumCharactersTyped = true;
                } else {
                  this.warningMessageExist = true;
                  this.areMinimumCharactersTyped = false
                }
              };
              
              (searchRes.length > 0) ? this.areResultsFound = true : this.areResultsFound = false;
            }),
          );
        })
      ).subscribe((sr: SearchResult[]) => {
        this.filteredList = sr;
      });
    });    

    this.cdRef.detectChanges();
  }

  preselect(ids){
    this.indexArray = ids; 
    this.searchOptionSelected = [...this.preselectedElements];

    this.formControl.setValue(this.searchOptionSelected);
    this.formControl.markAsTouched();
    this.formControl.markAsDirty();
    this.formControl.setErrors(null);      
  }  


  optionSelected(index: number, result:any) {
    if(this.multiSelect){
      if(this.searchOptionSelected.includes(result)){
        this.searchOptionSelected = this.searchOptionSelected.filter(el => el != result);    

        this.inputTextFormControl.setValue(this.searchOptionSelected, {emitEvent: false});

        (!this.indexArray.includes(index)) ? this.indexArray.push(index) : this.indexArray = this.indexArray.filter(i=> i!=index);
      } else {
        this.searchOptionSelected.push(result);
        (!this.indexArray.includes(index)) ? this.indexArray.push(index) : this.indexArray.splice(index,1);
      }

    } else {
      if(!this.searchOptionSelected.includes(result)){
        this.searchOptionSelected = [];
        this.searchOptionSelected.push(result);
        
        if(this.indexArray.length == 0){
          this.indexArray = [];
          this.indexArray.push(index);
        } else {
          this.indexArray.splice(0, 1, index);
        }       
      } else {
        this.searchOptionSelected = [];
        this.inputTextFormControl.setValue(null, { emitEvent: false })
        this.indexArray = [];
      }
      this.apply();
    }  
    
    if(this.optionSelected.length > 0) this.formControl.setErrors(null);    
    this.formControl.setValue(this.searchOptionSelected);
    this.formControl.markAsDirty();
    this.formControl.markAsTouched();
  }

  selectAll(idsFromTyping?:any){
    this.allSelected = true;

    const ids: number[] = this.filteredList.map((element, index) => {
      return index;
    });    
    
    this.indexArray = idsFromTyping ? idsFromTyping : ids; 
    this.searchOptionSelected = [...this.filteredList];  
    
    this.formControl.setValue(this.searchOptionSelected);
    this.formControl.markAsTouched();
    this.formControl.markAsDirty();
    this.formControl.setErrors(null);
  }

  deSelectAll(){
    this.allSelected = false;

    this.indexArray = [];
    this.searchOptionSelected = [];

    this.inputTextFormControl.setValue(null, {emitEvent: false})
    
    this.formControl.setValue(this.searchOptionSelected);
    this.formControl.markAsTouched();
    this.formControl.markAsDirty();
    
    this.selectedValues.emit([]);
  }

  apply(){
    this.formControl.setErrors(null);
    this.formControl.setValue(this.searchOptionSelected)
    this.selectedValues.emit(this.searchOptionSelected);
  }
}