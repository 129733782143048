import {
  Component,
  forwardRef,
  Injector,
  Input
} from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseInputComponent } from '../base-input.directive';


@Component({
  selector: 'fpc-input-check-box',
  templateUrl: './input-check-box.component.html',
  styleUrls: ['./input-check-box.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputCheckBoxComponent),
      multi: true,
    },    
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputCheckBoxComponent),
      multi: true,
    }
  ]
})
export class InputCheckBoxComponent extends BaseInputComponent{

  @Input() minlength!: string;
  @Input() maxlength!: string;
  @Input() labelColor!: string;
  @Input() labelPosition: 'top' | 'right' | 'left' = 'top';
  @Input() iconSize: string = 'md'
  @Input() inputSize!: string;
  @Input() inputClass: string = 'h4';
  @Input() labelClass: string;

  constructor(injector: Injector) {
    super(injector);
  }
}
