<div ngbAccordion #accordionTemplate="ngbAccordion" [closeOthers]="closeOthers" class=" highlited" [ngClass]="[accordionFlush ? 'accordion-flush':'', accordionSize == 'lg'?'accordion-lg':'']">
  <div ngbAccordionItem="{{item.id}}" #accordionItem="ngbAccordionItem" *ngFor="let item of accordionPannels">   
    <div ngbAccordionHeader class="accordion-button custom-header justify-content-between gap-3"
    [ngClass]="[
      (isAccordionOpened) ? 
      (
        (closeOthers && accordionItem?._collapsed) ? 
        'bg-gray-lighter text-gray-dark': 
        (accordionClass || accordionTextColorClass) ? 
        accordionClass + ' ' + accordionTextColorClass : 
        ''
      ) : 
      ''
      ]">
        <div class="accordion_header_container flex-grow-1 m-0" [ngClass]="item.iconClass ? 'd-flex gap-3':''">
          <i *ngIf="item.iconClass" [class]="item.iconClass"></i>
            <div class="d-flex justify-content-between">
              <div class="accordion_title_container d-flex flex-row gap-2 align-items-center">
                <p [class]="'accordion_title m-0 fw-bold ' + (titleClass ? titleClass : '')">{{item.title}}</p>
                <p [class]="'accordion_badge fw-bold m-0 ' + (badgeClass ? badgeClass : '') + ' ' + ((isAccordionOpened) ? ((closeOthers && accordionItem?.collapsed) ? 'text-gray-dark': (accordionTextColorClass) ? accordionTextColorClass : badgeColorClass): badgeColorClass)">{{item.badge}}</p>
              </div>
              <div class="d-flex align-items-center">
                <p [class]="'accordion_subtitle m-0 ' + (subtitleClass ? subtitleClass : '') ">{{item.subtitle}}</p>
              </div>              
            </div>
        </div>

        <!-- action icons-->
        <ng-container *ngIf="!accordionItem._collapsed">
          <fpc-button           
            *ngFor="let item of item.actionItems"
            [buttonLayoutType]="'icon'" 
            [buttonColorClass]="'primary'"
            [iconClass]="item.iconClass" 
            [iconSize]="item.iconSize ? item.iconSize : 'sm'"
            [disabled]="item.disabled"
            [active]="item.active"
            (buttonClick)="item.actionItem(accordionItem, item); item.active = !item.active"            
          ></fpc-button>
        </ng-container>
      

        <!-- collapse button-->
        <fpc-button 
          ngbAccordionToggle 
          [buttonLayoutType]="'icon'" 
          [buttonColorClass]="'primary'"
          [iconClass]="!accordionItem.collapsed ? 'fal fa-chevron-up' : 'fal fa-chevron-down'" 
          [iconSize]="iconSize ? iconSize : 'sm'"
          [active]="!accordionItem.collapsed"
          (buttonClick)="checkCollapsed(accordionItem, item.actionItems)">
        </fpc-button>
    </div>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody [class.nested-accordion]="item.panelItems" [class]="inset ? 'inset' : ''">
        <ng-template>
          <ng-container *ngIf="!item.panelItems; else nestedAccordion">
            <p *ngIf="item.content">{{ item.content }}</p>
            <a *ngIf="item.url" [href]="item.filepath" target="_blank">{{ item.url ? item.url : item.url }}</a>
            <ng-container *ngIf="item.template">
              <ng-container *ngTemplateOutlet="item.template; context: { id: item.id, data: item.templateData }"></ng-container>
            </ng-container>
          </ng-container>

          <ng-template #nestedAccordion>
            <div ngbAccordion [closeOthers]="true" *ngIf="item.panelItems">
              <div ngbAccordionItem #childAccordionItem="ngbAccordionItem" *ngFor=" let child of item.panelItems">
                <div ngbAccordionHeader class="accordion-button custom-header justify-content-between">
                  <div class="d-flex justify-content-between">
                    <div class="accordion_title_container d-flex flex-row gap-2 align-items-center">
                      <h4 class="accordion_title m-0 fw-bold">{{child.title}}</h4>
                      <h4 class="accordion_badge fw-bold m-0">{{child.badge}}</h4>
                    </div>
                    <div class="d-flex align-items-center">
                      <h4 class="accordion_subtitle m-0 gray-darker">{{child.subtitle}}</h4>
                    </div>              
                  </div>

                  <!-- childs action icons-->
                  <fpc-button
                    *ngFor="let item of child.actionItems"
                    [buttonLayoutType]="'icon'" 
                    [buttonColorClass]="'primary'"
                    [iconClass]="item.iconClass"                    
                    [disabled]="item.disabled"
                    [iconSize]="item.iconSize ? item.iconSize : 'sm'"
                    (buttonClick)="item.actionItem()"
                  ></fpc-button>

                  <!-- childs collapse button-->
                  <fpc-button 
                    ngbAccordionToggle 
                    [buttonLayoutType]="'icon'" 
                    [buttonColorClass]="'primary'"
                    [iconClass]="!childAccordionItem.collapsed ? 'fal fa-chevron-up' : 'fal fa-chevron-down'" 
                    [iconSize]="'sm'"
                    [buttonClass]="!childAccordionItem.collapsed ? 'active':''"
                    >
                  </fpc-button>

                </div>
                <div ngbAccordionCollapse>
                  <div ngbAccordionBody>
                    <ng-template>
                      <p *ngIf="child.content">{{child.content}}</p>
                      <a *ngIf="child.url" [href]="child.filepath" target="_blank">{{child.url? child.url : child.url }}</a>
                      <ng-container *ngIf="child.template">
                        <ng-container *ngTemplateOutlet="child.template"></ng-container>
                      </ng-container>
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </ng-template>
      </div>
    </div>
  </div>
</div>