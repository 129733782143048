import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';

@Directive({
  selector: '[copy-text-pre]'
})
export class CopyPreDirective {

  @HostListener('click') click() {
    this.copyToClipboard();
  }

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private clipboardService: ClipboardService
  ) { }

  copyToClipboard() {
    this.clipboardService.copy(this.el.nativeElement.innerText);
  }

}
