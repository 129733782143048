import { InjectionToken } from '@angular/core';
import { SharedConfig } from '../../msal/msal.module';

export const CONFIG_LOADER_SERVICE = new InjectionToken<ConfigLoaderService>("ConfigLoaderService");

export interface ConfigLoaderService {
  config: SharedConfig;
  dataHubOverride: boolean;

  getAichatbotResourceUrl: (url: string) => string;
}

export class MockConfigLoaderService {}