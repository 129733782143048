<fpc-button 
    [buttonLayoutType]="'collapse-button'" 
    [id]="id" 
    [buttonColorClass]="buttonColorClass"
    [buttonClass]="buttonClass" 
    [iconClass]="iconClass" 
    [text]="buttonText"
    [iconLayout]="iconLayout" 
    [iconSize]="iconSize" 
    [iconStyle]="iconStyle" 
    [active]="active" 
    (buttonClick)="isCollapsed = !isCollapsed"
></fpc-button>

<div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed" class="collapsible-panel mt-2 w-100">
    <ng-content></ng-content>
</div>