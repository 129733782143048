<!-- Header -->
<div id="header_container" [ngStyle]="{'z-index': hamburgerOpened ?999999999: 99999}">
  <div id="nav">

    <!-- Nav Left -->
    <div class="nav_left">
      <!-- Hamburger + Logo -->
      <div class="hamburger_logo">
        <!-- Hamburger -->
        <div class="hamburger" [ngClass]="{'opened': hamburgerOpened===true}" (click)="hamburgerClick($event)" title="Main menu">
          <div class="bar"></div>
        </div>
        <!-- X Hamburger -->

        <!-- Logo -->
        <div class="logo intranet" intranet title="Intranet homepage">
          <a (click)="logoClick($event)" alt="Home Page" class="stretched-link">
            <img src="https://designassets.fosterandpartners.com/assets/images/svg/FP_Logo_Desktop.svg" title="Foster + Partner" alt="Foster + Partner" />
          </a>
        </div>
        <!-- X Logo -->
      </div>
      <!-- X Hamburger + Logo -->

      <!-- Navigation Icons -->
      <div class="navigation_icons">
        <div class="module" title="Search" (click)="searchIconClick()">
          <i class="fal fa-search"></i>
        </div>
        <div class="module" [ngClass]="{'notification' : model?.noOfNotifications > 0}" title="Notification panel" (click)="notificationsPanelClick($event)">
          <i class="fal fa-bell hide_xs open_notifications_panel">
            <span *ngIf="model && model?.noOfNotifications" class="badge badge-circle d-flex align-items-center justify-content-center"
              [ngClass]="{'blue' : model?.noOfNotifications == 0}">{{model?.noOfNotifications}}</span>
          </i>
        </div>
        <!-- Settings & Preferences -->
        <div class="module sidebar_button" title="Settings and Preferences" (click)="settingsAndPreferencesClick($event)">
          <i class="fal fa-light fa-gear"></i>
        </div>
        <!-- X Settings & Preferences -->
      </div>
      <!-- X Navigation Icons -->
    </div>
    <!-- X Nav Left -->

    <!-- Nav Right -->
    <div class="nav_right">
      <!-- Avatar -->
      <div class="module avatar" title="Profile panel" (click)="profilePanelClick($event)">
        <div class="image " [ngStyle]="userProfilePic ? {'background-image':'url('+userProfilePic+')'} :''">
          <i *ngIf="!userProfilePic && userIcon" class="fal fa-user"></i>
        </div>
      </div>
      <!-- X Avatar -->

      <!-- Sidebar Button -->
      <div class="navigation_icons">

        <div class="module sidebar_button" [ngClass]="'d-lg-none'" title="Sidebar" (click)="mobileSidebarClick($event)">
          <i class="fal fa-chevron-left"></i>
        </div>

        <div class="module sidebar_button" [ngClass]="'d-none d-lg-flex'" [title]="sidebarCollapsed ? 'Show sidebar' : 'Hide sidebar'"
          (click)="collapseSidebarClick()">
          <i class="fal" [ngClass]="[sidebarCollapsed ? 'fa-chevron-left' : 'fa-chevron-right']"></i>
        </div>

      </div>
      <!-- X Sidebar Button -->
    </div>
    <!-- X Nav Right -->
  </div>
</div>
<!-- X Header -->

<!-- *** Main Menu Wrapper *** -->
<nav *ngIf="model" id="main_menu_wrapper" (clickOutside)="onClickedOutside($event)">
  <ul ngbNav #mainsidebar="ngbNav" [(activeId)]="active" class="navigation_menu">
    <li class="navigation_menu-item" [ngClass]="getMenuItemClasses(item)" [ngbNavItem]="item.id" *ngFor="let item of model.items">
      <a class="navigation_menu-link d-flex align-items-center" title="{{item.name}}" ngbNavLink
        (click)="navLinkClick(
          $event, 
          item.link != '/' ? 'search' : item.link,
          (item.link != 'search' && item.link != '/') ? {cardType:getRouterLabel(item.link),pageSize:pageSize} : '', item)">
        <i class="fal {{item.badge}}"></i>
        <h3> {{item.name}} </h3>
      </a>
      <a *ngIf="item.link === '/'" ngbNavLink (click)="navLinkClick($event, '/home')" style="display: none"></a>
    </li>
  </ul>
  <div [ngbNavOutlet]="mainsidebar"></div>
</nav>
<!-- *** X Main Menu Wrapper *** -->