import { DOCUMENT } from '@angular/common';
import { AfterContentInit, Component, ElementRef, Inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'fpc-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements AfterContentInit {

  @Input() hasGlobalError: boolean = false;

  constructor(private el: ElementRef, @Inject(DOCUMENT) private document: Document) { }

  ngAfterContentInit() {
    let myTag = this.el.nativeElement.querySelector("#footer_container");
    let designAssetUrl = this.document.location.hostname.includes('designassets-staging') || this.document.location.hostname.includes('localhost');
    setTimeout(() => {
      myTag.classList.remove('opacity-0');
    }, designAssetUrl ? 0 : 5000);
  }

}
