import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'fpc-message-container',
  templateUrl: './message-container.component.html',
  styleUrls: ['./message-container.component.scss']
})
export class MessageContainerComponent implements OnInit {
  @Input() public content: string;

  constructor() { }

  ngOnInit(): void {
  }

}
