
import { Injectable, Inject } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { DOCUMENT } from '@angular/common'; 

  export interface RedirectionExtras extends NavigationExtras {
    target?: string;
  }
  
  @Injectable({
    providedIn: 'root'
  })
  export class NavigateService {
  
    constructor(
      readonly router: Router,
      @Inject(DOCUMENT) readonly document: Document
      ) {}
  
    /** The Window object from Document defaultView */
    get window(): Window { return this.document?.defaultView as any }
  
    /** Returns true if the given url looks external */
    public external(url: string): boolean {
      /// ^http(?:s)?:\/{2}\S+$/.test(url);
      return url.includes(":")
    }
  
    /** Redirects to the specified external link with the mediation of the router */
    public redirect(url: string, target = '_blank'): Promise<boolean> {      
        return new Promise<boolean>( (resolve, reject) => {
         
         try { resolve(!!this.window.open(url, target)); }
         catch(e) { reject(e); }
      });
    }
  
    /** Navigates to the given url, redirecting when necessary 
     * @param url An absolute URL. The function does not apply any delta to the current URL. 
     * When starting with 'http(s)://' triggers the external redirection. 
     * @param extras (optional). An object containing properties that modify the navigation strategy. 
     * The function ignores any properties that would change the provided URL. 
     */
    public navigate(url: any, extras?: RedirectionExtras): Promise<boolean> {
      const encodedUrl = typeof url === "string" ? url : url.join('/');      
      
      return this.external(url) ? 
        // Redirects to external link
        this.redirect(encodedUrl, extras && extras.target) : 
        // Navigates with the router otherwise 
        extras && extras.target == "_blank" ? this.redirect(encodedUrl) : this.router.navigateByUrl(encodedUrl, extras);
    }

  }