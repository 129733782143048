import { Directive, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

export interface SearchEvent {
  column: string;
  value: string;
}

@Directive({
  selector: 'input[searchable]',
  host: {
    '[class.active]': 'value !== null',
    '(keyup)': 'keyUp($event)',
    '(click)': 'click($event)',
    '(blur)': 'blur($event)'
  }
})
export class SearchHeader implements OnInit {

  elRef: ElementRef;
  valueOriginal: string;
  @Input() searchable: string;
  @Output() search = new EventEmitter<SearchEvent>();
  internalSearchEvent = new Subject<SearchEvent>();

  constructor(el: ElementRef) {
    this.elRef = el
    this.internalSearchEvent.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe((data) => {
      this.search.emit(data);
    })
  }

  ngOnInit(): void {
    this.valueOriginal = this.elRef.nativeElement.value;
  }

  click(event) {
    if (event.target.value == this.valueOriginal) {
      event.target.value = "";
    }
  }

  blur(event) {
    if (event.target.value == "")
      event.target.value = this.valueOriginal;
  }

  keyUp(event) {
    this.internalSearchEvent.next({ column: this.searchable, value: event.target.value });
  }
}