import { Component, Input, EventEmitter } from '@angular/core';
import { NavigateService } from '../../../shared/_services/navigate.service';
@Component({
  selector: 'fpc-link-card',
  templateUrl: './link-card.component.html',
  styleUrls: ['./link-card.component.scss']
})
export class LinkCardComponent {
  @Input() content: string = "";
  @Input() link!: string;
  @Input() linkPosition: string = "left";
  @Input() header!: string;
  @Input() subHeader!: string;


  constructor(private navigateService: NavigateService) { }

  linkAction(event):void {
    if (this.link) {
      this.navigateService.navigate(this.link);
    }
    
  }

}
