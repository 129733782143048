import { Pipe, PipeTransform } from "@angular/core";
@Pipe({
  name: 'categoryDropdownTitleCase'
})

export class CategoryDropdownTitleCasePipe implements PipeTransform {
  transform(value: any): string {
    let char = " ";
    if (value.indexOf('/') > -1) {
      char = "/";
    };
    if (value.indexOf('-') > -1) {
      char = "-";
    };
    const valArr = value.split(char);
    const format = (input: string): string => {
      return input;    
    }
    return valArr.map((val, index, arr) => {
      if (val == "&") {
        val = "and";
      }
      if (arr.length > 2) {
        if (index == 1) return val;
        else return format(val);
      }
      else {
        return format(val);
      }
    }).join(char);
  }
}