<fpc-preloader *ngIf="!feedbackData"></fpc-preloader>
<div *ngIf="feedbackData" class="d-flex flex-column gap-5">

  <ng-container *ngIf="!isFeedbackSubmited">
    <div class="title">
      <h4 class="text-center">
        Hi {{userFirstName}}, we use feedback to help us shape and improve the intranet, we’d love to hear about your experience. Please let us know your thoughts below
      </h4>
    </div>

    <div *ngFor="let question of feedbackData.questions">
      <h3 *ngIf="question.question?.length" class="fw-bold text-center text-primary pb-4"> {{question.question}} </h3>
      <!-- Question Single Choice -->

      <div class="d-flex text-center gap-1" *ngIf="question.type == 'choice'">
        <div *ngFor="let choice of question.choice" class="rate-icon d-flex flex-column align-items-center" [ngClass]="choice.active ? 'active':''"
          (click)="selectChoice(question.id, choice.id)">
          <div class="icon {{choice.iconColor}} d-flex align-items-center justify-content-center">
            <i class="fas {{choice.icon}}"></i>
          </div>
          <h5 class="fw-bold mb-0">{{choice.text}}</h5>
        </div>
      </div>
      <!-- X Question Single Choice -->


      <!-- Question String Type -->

      <div class="w-100 align-items-center justify-content-center" *ngIf="question.type == 'string'">

        <!-- Text Area -->
        <fpc-input-textarea [formControl]="formControl" [label]="question.question" [placeholder]="'Tell us about your experience (Optional)...'"
          [labelColor]="'text-primary'" (valueChanges)="getCommentText(question.id); changeInCommentBox($event, question.id)"></fpc-input-textarea>
        <!-- X Text Area -->

      </div>
      <!-- X Question String Type -->


      <!-- Question Multi Choice -->

      <div class="d-flex justify-content-between" *ngIf="question.type == 'multichoice'">
        <div class="d-flex flex-column align-items-center" *ngFor="let choice of question.choice; let i=index">

          <input type="checkbox" id="{{choice.text}}" name="interest" [(ngModel)]="choice.checked" (change)="selectChoice(question.id, choice.id)"
            [checked]="choice.checked" />
          <label for="{{choice.text}}">
            <h5 class="mb-0 fw-bold">{{choice.text}}</h5>
          </label>

        </div>
      </div>
      <!-- X Question Single Choice -->

    </div>

    <div class="d-flex align-items-center justify-content-center">

      <fpc-button [text]="'Send Feedback'" [buttonColorClass]="'primary'" [buttonClass]="'fixed-width'"
        [disabled]="(feedbackData.questions[0].response.answer.length <= 0) || (feedbackData.questions[2].response.answer.length <= 0)"
        (buttonClick)="onSubmitBtnClick()"></fpc-button>


    </div>
  </ng-container>


  <div class="carousel-item active" *ngIf="isFeedbackSubmited">
    <div class="mx-auto text-center">
      <div class="title pb-3">
        <h4 class="mb-4">Thank you for your feedback.<br> If you have further feedback in the future you can return to this area and submit more.</h4>
      </div>
    </div>
  </div>

</div>