import { Component, forwardRef, Input, Injector } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseInputComponent } from '../base-input.directive';

@Component({
  selector: 'fpc-input-textarea',
  templateUrl: './input-textarea.component.html',
  styleUrls: ['./input-textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputTextareaComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputTextareaComponent),
      multi: true,
    }
  ]
})
export class InputTextareaComponent extends BaseInputComponent {

  @Input() labelColor!: string;
  @Input() labelClass = 'mb-2';
  @Input() iconSize: string = 'md';  
  @Input() inputClass: string = 'h4';

  constructor(injector: Injector) {
    super(injector);
  }

}
