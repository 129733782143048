
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { CONFIG_LOADER_SERVICE, ConfigLoaderService } from '../../shared/_services/config-loader.service';

@Injectable({
  providedIn: 'root'
})

export class AiChatBotService {

  constructor(
    readonly router: Router,
    readonly http: HttpClient,
    @Inject(CONFIG_LOADER_SERVICE) readonly configLoaderService: ConfigLoaderService
  ) { }

  getHeaders() {
    const ParseHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return ParseHeaders;
  }

  sendMessage(message: string) {
    const body = message;
    const headers = new HttpHeaders().set('Content-Type', 'text');

    return this.http.post<any>(this.configLoaderService.getAichatbotResourceUrl(''), { message: body }, { headers: headers });

  }


}