import { Component, OnInit, ViewChild, ElementRef, Inject, Injector, Input } from '@angular/core';
import { first } from 'rxjs/operators';
import { FeedbackForm } from '../../shared/_models/feedback/feedback';
import { FeedbackFormResponse } from '../../shared/_models/feedback/feedbackFormResponse';
import { FeedbackQuestion } from '../../shared/_models/feedback/feedbackQuestion';
import { FeedbackQuestionType } from '../../shared/_models/feedback/feedbackQuestionType';
import { AppService, APP_SERVICE, DataService, DATA_SERVICE } from '../../shared/_services';
import { BaseInputComponent } from '../../../presentational/components/form-elements/base-input.directive';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'fpc-feedback-form-widget',
  templateUrl: './feedback-form-widget.component.html',
  styleUrls: ['./feedback-form-widget.component.scss']
})
export class FeedbackFormWidgetComponent extends BaseInputComponent implements OnInit {

  @ViewChild('feedbackForm') feedbackForm: ElementRef;
  
  feedbackData: FeedbackForm;

  isFeedbackSubmited = false;

  @Input() userFirstName: string;

  constructor(injector: Injector, @Inject(APP_SERVICE) private appService: AppService, @Inject(DATA_SERVICE) private dataService: DataService) {
    super(injector);

    this.dataService.feedbackFormClick.subscribe(() => {   

      if (this.feedbackForm) {
        if (this.isFeedbackSubmited) { 
          this.isFeedbackSubmited = false;
          this.getFeedbackDataFromAPI();
        }
        setTimeout(() => this.feedbackForm.nativeElement.scrollIntoView({
          behavior: 'smooth', block: 'center'
        }), 0);
      }
    });
  }

  ngOnInit() {
    if (localStorage.getItem('feedbackForm')) {
      this.getFeedbackDataFromStorage();
    } else {
      this.getFeedbackDataFromAPI();
    }
  }

  getFeedbackDataFromStorage() {
    let feedBackFormData = JSON.parse(localStorage.getItem('feedbackForm'));
    this.feedbackData = feedBackFormData.feedbackData;

    this.feedbackData.questions.forEach((question)=>{
      if(question.id== '0'){
         question.choice.forEach((choice)=>{
          if(question.response.answer.includes(choice.id)){
            choice.checked = true;
          }
        })
      }  
    });

    this.formControl.setValue(this.feedbackData.questions[1].response.answer[0]);
  }

  getFeedbackDataFromAPI() {
    this.appService.getFeedBackForm().pipe(first()).subscribe(result => {
      if (result) {
        this.updateFeedBackData(result);
      }
    });
  }

  updateFeedBackData(result) {
     result.questions.forEach((question: FeedbackQuestion) => {
      question.response = { id: question.id, answer: [] };  
     });
     this.feedbackData = { formId: result.formId, formTitle: result.formTitle, headerIcon: result.headerIcon, questions: result.questions };
  
  }

  selectChoice(questionId, choiceId) {
    let feedbackquestion = this.feedbackData.questions.filter(q=>q.id == questionId)[0];
    if (feedbackquestion) {
      
   

      if (feedbackquestion.type === FeedbackQuestionType.CHOICE) {
        
        this.feedbackData.questions.forEach((question) => {
          if(questionId == question.id){
            question.choice?.forEach((c) => {
              if (c.id === choiceId) {
                c.active = c.active === true ? false : true;
              } else {
                c.active = false;
              }       
            });
          }      
        });

        if (feedbackquestion.response.answer.length > 0) {
          let prevAnsware = feedbackquestion.response.answer[0];
          feedbackquestion.response.answer.splice(0, 1);
          if(prevAnsware != choiceId){
            feedbackquestion.response.answer.push(choiceId);
          }          
        } else {
         
            feedbackquestion.response.answer.push(choiceId);
                   
        }
       
      } else if (feedbackquestion.type === FeedbackQuestionType.MULTICHOICE) {
        if (feedbackquestion.response.answer.length > 0) {

          

          let itemIndex = feedbackquestion.response.answer.findIndex(item => item === choiceId);
          if (itemIndex != -1) {
            feedbackquestion.response.answer.splice(itemIndex, 1);            
          } else {
            feedbackquestion.response.answer.push(choiceId);
          }
        } else {
          feedbackquestion.response.answer.push(choiceId);
        }
      }
    }
    this.updateLocalStorageData();
  }

  onSubmitBtnClick() {
    let feedbackFormResponse: FeedbackFormResponse = { formId: this.feedbackData.formId, responses: [], pageUrl: window.location.href };
       
      this.feedbackData.questions.forEach(question => {
        feedbackFormResponse.responses.push(question.response);       
      });
      
      this.appService.postUserFeedBack(feedbackFormResponse).subscribe(result => {
        this.isFeedbackSubmited = true;
        localStorage.removeItem('feedbackForm');
      });
  }

  changeInCommentBox(text: string, questionId) {
    if (text.length > 0) {
      if (this.feedbackData.questions.filter(q=>q.id == questionId)[0].response.answer.length > 0) {
        this.feedbackData.questions.filter(q=>q.id == questionId)[0].response.answer[0] = text;
      } else {
        this.feedbackData.questions.filter(q=>q.id == questionId)[0].response.answer.push(text);
      }
    } else if (this.feedbackData.questions.filter(q=>q.id == questionId)[0].response.answer.length > 0) {
      this.feedbackData.questions.filter(q=>q.id == questionId)[0].response.answer.splice(0, 1);
    }
    this.updateLocalStorageData();
  }

  updateLocalStorageData() {
    localStorage.setItem('feedbackForm', JSON.stringify({      
      'feedbackData': this.feedbackData
    }));
  }

  getCommentText(questionId) {
    if (this.feedbackData.questions.filter(q=>q.id == questionId)[0].response.answer.length > 0) {
      return this.feedbackData.questions.filter(q=>q.id == questionId)[0].response.answer[0];
    }
  }
}