import { InteractionType } from '@azure/msal-browser';
import { SharedConfig } from '../../msal';
import { MsalInterceptorConfig } from '../../msal/msal.interceptor.config';

export const MSALInterceptorConfigFactory = (
  config: SharedConfig
): MsalInterceptorConfig => {
  const protectedResourceMap = new Map<string, Array<string>>();

  for (let name in config.Api_Spec) {
    const spec = config.Api_Spec[name];
    let scopes = ['openid', 'profile']
    if (spec.Client_Id) {
      if (spec.Scopes) {
        for (let scope of spec.Scopes) {
          scopes.push(`api://${spec.Client_Id}/${scope}`);
        }
      }
      else scopes.push(`api://${spec.Client_Id}/API.Read`);
    }
    protectedResourceMap.set(spec.Endpoint, scopes);
  }

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
};
