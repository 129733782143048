import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'DesignAssets';
  isHome: boolean;

  constructor(private router: Router) {
    this.isHome = location.pathname == "/home";
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isHome = location.pathname == "/home";
      }
    })
  }
}
