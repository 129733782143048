import { Component, OnInit, Input, Output, EventEmitter, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbDate, NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';

export interface Filter {
  icon: string;
  items: FilterItem[];
  label: string;
  title: string;
  title2: string;
  type: string;
}
export interface FilterItem {
  badge?: string;
  id: number;
  label?: string;
  name?: string;
  title?: string;
  selectedDate?: string;
  values: string[];
}

@Component({
  selector: 'fpc-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  providers: [NgbDropdownConfig], 
})


export class FilterComponent implements OnInit {
  _filter:Filter;
  filterTitle:string='';
  
  _filtersItems: Map<number, FilterItem>;
  searchText: string;

  private _filterItemsCopy: Map<number, FilterItem>;

  _selectedFiltersMap: Map<number, FilterItem>;

  @Input() _selectedFiltersMapInital: Map<number, FilterItem>;

  isCollapsed: boolean = true;
  isLocationDDCollapsed: boolean = true;
  _filterTitle: string = "Filters";
  _filterTitle2: string; //second part faded, used in dashboard for numbers
  _filterIcon: string; 

  tabMenuClass: string = 'search-active';

  @Input() set filter(filter: Filter) {
    // eslint-disable-next-line no-console
    console.log('filters_initial_filter',filter);
    
    this._filtersItems = new Map<number, FilterItem>();
    this._filterItemsCopy = new Map<number, FilterItem>();
    
    filter.items.forEach((item: FilterItem) => {
      this._filtersItems.set(item.id, item);
      this._filterItemsCopy.set(item.id, JSON.parse(JSON.stringify(item)));
    });
    // eslint-disable-next-line no-console
    console.log('_filtersItems', this._filtersItems);
    // eslint-disable-next-line no-console
    console.log('_filterItemsCopy', this._filterItemsCopy);
  }
  get filter(): Filter {
    return this._filter;
  }

  @Input() hideSelectDeselectAll: boolean = false;

  @Input() screenWidth: number;

  @Input() scrollable: boolean = false;


  @Input() set selectedFilters(filterItems: FilterItem[]) {
    // eslint-disable-next-line no-console
    console.log('selected_filters', filterItems);
    if (filterItems) {
      if (!this._selectedFiltersMap) {
        this._selectedFiltersMap = new Map<number, FilterItem>();
      }
      if (!this._selectedFiltersMapInital) {
        this._selectedFiltersMapInital = new Map<number, FilterItem>();
      }

      filterItems.forEach((item: FilterItem) => {
        this._selectedFiltersMap.set(item.id, { "id": item.id, title: item.name, "values": item.values });
        // eslint-disable-next-line no-console
        console.log('_selectedFiltersMap', this._selectedFiltersMap);
        const filterItemCopy = JSON.parse(JSON.stringify(item));
        this._selectedFiltersMapInital.set(filterItemCopy.id, { "id": filterItemCopy.id, title: filterItemCopy.name, "values": filterItemCopy.values });

        this.filterTitle = this._selectedFiltersMapInital.has(item.id) ? ((item.name == 'Group' ?
        'Studio / Group / Team' : item.label) + ': ') : '';
      });
      if (!this.noOfFilterSelected)
        this.noOfFilterSelected = filterItems.length;

      this.disableAndResetOtherCategories();
    }
  }

  @Input() disableOtherCategoriesWhenSelected: boolean = false;

  @Output() getFilterSelectionChanged = new EventEmitter<string>();
  @Output() getDateSelectionChanged: EventEmitter<{ id: number, selectedDate: { fromDate: NgbDate, toDate: NgbDate } }> = new EventEmitter();

  noOfFilterSelected: number;

  expandActive: boolean = false;

  @Input() showDatePicker: boolean = false;

  @ViewChildren('ddb') ngbDropdownButtons: QueryList<ElementRef>

  inputSearch = new(FormControl);
  
  constructor(config: NgbDropdownConfig) {
    config.autoClose = 'outside';
  }

  ngOnInit() {
    if (!this._selectedFiltersMap)
      this._selectedFiltersMap = new Map<number, FilterItem>();
    if (!this._selectedFiltersMapInital)
      this._selectedFiltersMapInital = new Map<number, FilterItem>();

    if (this.disableOtherCategoriesWhenSelected)
      this.disableAndResetOtherCategories();
  }

  /**
   * is called when the filter is selcted from dropdown
   * @param event 
   * @param filterId 
   * @param fltTitle 
   * @param valueSelected 
   */
  onFilterSelected(event, filterId, fltTitle, selectedDate, valueSelected) {
    const item = this._selectedFiltersMap.get(filterId);
    if (item) {
      // if item exists that means the item was already selected
      const index: number = item.values.indexOf(valueSelected);
      if (index !== -1) {
        // index was found so remove the item from selected array
        item.values.splice(index, 1);
        if (item.values.length == 0)
          this._selectedFiltersMap.delete(filterId);
      } else {
        item.values.push(valueSelected);
      }
      item.selectedDate = selectedDate;
    } else {      
      const values: string[] = [];
      values.push(valueSelected);
      this._selectedFiltersMap.set(filterId, { "id": filterId, "title": fltTitle, "values": values });
    }
  }

  onDateSelectionChanged(event, filter) {
    filter.selectedDate = event;
    this.getDateSelectionChanged.emit({ id: filter.id, selectedDate: event });
    setTimeout(() => {
      this.ngbDropdownButtons.toArray().forEach((el: ElementRef) => {
        if (el.nativeElement.parentElement.classList.contains('show')) {
          el.nativeElement.click();
        }
      });
    }, 200);
  } 

  disableAndResetOtherCategories() {
    if (this.disableOtherCategoriesWhenSelected) {

      let filterId;
      let noOfActiveFilters = 0;
      this._selectedFiltersMap.forEach((value: FilterItem) => {
        // eslint-disable-next-line no-console
        console.log('value', value);
        if (value.values.length > 0) {
          filterId = value.id;
          noOfActiveFilters++;
        }
      });
     
        [...this._filtersItems.entries()].map((filter) => filter[0]).forEach((filterIndex: number) => {
          if (filterIndex != filterId) {
            const filterObj = this._filtersItems.get(filterIndex);
            if (filterId && noOfActiveFilters == 1) {
            filterObj.badge = "-1";
            }
            if(noOfActiveFilters == 0){
              filterObj.badge = "0";
            }
            this._filtersItems.set(filterIndex, filterObj);
          }
        });   
    }
  }

  isApplyButtonActive(filterId: number) {
    const filter1 = this._selectedFiltersMap.get(filterId);
    const filter2 = this._selectedFiltersMapInital.get(filterId);
    return this.compareFilterItems(
      filter1 && filter1.values ? filter1.values : [],
      filter2 && filter2.values ? filter2.values : []
    );
  }

  compareFilterItems(fitems1, fitems2): boolean {
    if (fitems1.length == 0 && fitems2.length == 0) return false;
    else return JSON.stringify(fitems1.sort()) != JSON.stringify(fitems2.sort());
  }

  sortFilters(filterId: number) {  
    this._filtersItems.get(filterId).values.sort((a, b) => {
      return this.isFilterSelected(filterId, a) ? (this.isFilterSelected(filterId, b) ? 1 : -1) : 0;
    });
  }

  asIsOrder(a, b) {
    const aStepId = parseInt(a.value.order, 10);
    const bStepId = parseInt(b.value.order, 10);
    return aStepId > bStepId ? 1 : (bStepId > aStepId ? -1 : 0);
  }

  /**
   * 
   * @param filterId 
   * @param valueSelected 
   */
  isFilterSelected(filterId, valueSelected) {
    const item = this._selectedFiltersMap.get(filterId);
    return item && item.values ? item.values.includes(valueSelected) : false;
  }

  areAllFiltersSelected(filterId, allValues) {
    if (allValues.length == 0) return false;
    let result = true; // assume all are selected at the beginning
    for (const value of allValues) {
      if (!this.isFilterSelected(filterId, value)) {
        result = this.isFilterSelected(filterId, value);
        break;
      }
    }
    return result;
  }

  /**
   * get the title of the filter dropdown button. title contains all the selected filters 
   * @param filterId 
   * @param defaultValue if true: return defaultValue of the filter otherwise get names of the filters applied
   */
  getFilterBtnTitle(filterId, defaultValue) {
    const itemFound = this._selectedFiltersMapInital.get(filterId);
    if (defaultValue)
      return itemFound ? itemFound.title : '';
    else {
      return itemFound.values && itemFound.values.length > 0 ? this.ifStudiosSortWithThemFirst(itemFound.values).join(', ') : itemFound.title;
    }
  }

  ifStudiosSortWithThemFirst(values) {
    const sortOrder = ["Studio 1", "Studio 2", "Studio 3", "Studio 4", "Studio 5", "Studio 6"];
    return values.sort((a, b) => {
      let indA = sortOrder.indexOf(a);
      let indB = sortOrder.indexOf(b);

      if (indA == -1) {
        indA = sortOrder.length;
      }
      if (indB == -1) {
        indB = sortOrder.length;
      }

      return indA - indB;
    });
  }

  applyFilterSelection(event, filterId) {
    event.preventDefault();

    // recalculate the number of filters again
    this.noOfFilterSelected = 0;
    this._selectedFiltersMap.forEach((value: FilterItem) => {
      this.noOfFilterSelected = this.noOfFilterSelected + value.values.length;
    });

    this.disableAndResetOtherCategories();

    this.sortFilters(filterId);
    this.filterSelectionChanged();
  }

  /**
   * 
   * @param event 
   * @param filterId 
   */
  onSelectAll(event, filter, apply?: boolean) {
    const filterValues = [...filter.values];
    this._selectedFiltersMap.set(filter.id, { "id": filter.id, "title": filter.title, "values": filterValues, "badge": filter.badge });
    if (apply) this.applyFilterSelection(event, filter.id);
  }

  selectCategoryIfDisabled(event, filter) {
    if (filter.value.badge == '-1') {
      event.preventDefault();
      this._selectedFiltersMap.clear();
      filter.value.badge = '0';
      this.onSelectAll(event, filter.value, true);
    }
  }

  
  /**
   * remove the items from selected list
   * @param event 
   * @param filterId id of the filter on which the opeartion is being performed
   */
  onDeSelectAll(event, filter, apply?: boolean) {
    this._selectedFiltersMap.delete(filter.id);
    if (apply) this.applyFilterSelection(event, filter.id);
  }

  /**
   * Checks if some filter is applied
   * @param filterId 
   */
  hasFilter(filterId) {
    return this._selectedFiltersMap.has(filterId);
  }

  /**
   * 
   * @param event 
   * @param filterId 
   * @param value 
   */
  handleInput(event: KeyboardEvent, filterId, value) {
    const internalFilterArr = this._filtersItems.get(filterId);
    const selectedFilter = this._filterItemsCopy.get(filterId);
    if (value) {
      internalFilterArr.values = selectedFilter.values.filter(item => item.toLowerCase().includes(value.toLowerCase()));
    } else {
      internalFilterArr.values = selectedFilter.values;
    }
    this.sortFilters(filterId);
  }

  /**
   * clear all the filters
   */
  clearAllFilters() {
    this._selectedFiltersMap.clear();
    this.noOfFilterSelected = 0;

    this.disableAndResetOtherCategories();

    this.filterSelectionChanged();
  }

  filterSelectionChanged() {
    this._selectedFiltersMapInital = new Map(this._selectedFiltersMap);
    this.getFilterSelectionChanged.emit(JSON.stringify(Array.from(this._selectedFiltersMap.values())));
  }

  resetFilterValues(filterId) {
    this.searchText = null;
    this._filtersItems.get(filterId).values = this._filterItemsCopy.get(filterId).values;
    this.sortFilters(filterId);
  }

  
}