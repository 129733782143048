import { AfterViewInit, Component, ContentChild, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { TemplateMarkerDirective } from '../../directives/templateMarker.directive';

@Component({
  selector: 'app-showcase-section-tab',
  templateUrl: './showcase-section-tab.component.html',
  styleUrls: ['./showcase-section-tab.component.scss']
})
export class ShowcaseSectionTabComponent implements OnInit, AfterViewInit {

  @Input() listNavItem = [];
  @Input() active: string;
  @Input() title: string;
  @Input() selector: string;

  @ContentChild(TemplateMarkerDirective) content!: TemplateMarkerDirective;

  constructor(private cd: ChangeDetectorRef) { }

  ngOnInit(): void {

  }

  ngAfterViewInit() {
    this.cd.detectChanges()
  }

}
