import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LinkListComponent } from './link-list.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [LinkListComponent],
  imports: [CommonModule, RouterModule],
  exports: [LinkListComponent],
})
export class LinkListModule {}
