import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'tableApiPropFilter'
})
export class TableApiPropFilter implements PipeTransform {
  transform(value: any, type: string) {
    return value.filter((row) => {
      return type == 'Inputs' ? row.name.includes('[') : !row.name.includes('[');
    });
  }
}