import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from './button.component';
import { RouterModule } from '@angular/router';
import { NgbDropdownModule, NgbPopoverModule  } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [ButtonComponent],
  imports: [RouterModule, CommonModule, NgbDropdownModule, NgbPopoverModule ],
  exports: [ButtonComponent]
})
export class ButtonModule {}
