<ng-container *ngIf="reactionLayout === 'page-reactions'">
  <ng-container *ngIf="reaction && !reaction.disableReactions">
    <div class="btn-group w-100" role="group" aria-label="Default button group">
      <fpc-button class="flex-fill" title="Like" [buttonColorClass]="'outline-white'" [buttonClass]="'w-100' + ' ' + (reaction.user_reaction=='Like'? 'after-border':'')"
        [ngStyle]="{ '--button-bg-color': '#266FA6' }" [buttonHoverClass]="'after-border'"
        [iconClass]="'fa-thumbs-up fa-lg' + ' ' + (reaction.user_reaction === 'Like' ? 'fa-solid text-turquoise' : 'fal')" [iconHoverClass]="'text-turquoise'"
        (buttonClick)="onReactionSelectClick('Like')"></fpc-button>

      <fpc-button class="flex-fill" title="Celebrate" [buttonColorClass]="'outline-white'" [ngStyle]="{ '--button-bg-color': '#c78bbb' }"
        [buttonHoverClass]="'after-border'" [buttonClass]="'w-100'  + ' ' + (reaction.user_reaction=='Celebrate' ? 'after-border' :'')"
        [iconClass]="'fa-hands-clapping fa-flip-horizontal fa-lg ' + (reaction.user_reaction=='Celebrate' ? 'fa-solid text-medium-purple':'fal')"
        [iconHoverClass]="'text-medium-purple'" (buttonClick)="onReactionSelectClick('Celebrate')"></fpc-button>

      <fpc-button class="flex-fill" title="Love" [buttonColorClass]="'outline-white'" [ngStyle]="{ '--button-bg-color': '#dc3545' }"
        [buttonClass]="'w-100' + ' ' + (reaction.user_reaction=='Love' ? 'after-border' :'')" [buttonHoverClass]="'after-border'"
        [iconClass]="'fa-heart fa-lg' + ' '+ (reaction.user_reaction=='Love' ? 'fa-solid text-dark-red':'fal')" [iconHoverClass]="'text-dark-red'"
        (buttonClick)="onReactionSelectClick('Love')"></fpc-button>

      <fpc-button class="flex-fill" title="Insightful" [buttonColorClass]="'outline-white'" [ngStyle]="{ '--button-bg-color': '#ffc107' }"
        [buttonClass]="'w-100' + ' ' + (reaction.user_reaction=='Insightful'? 'after-border':'')" [buttonHoverClass]="'after-border'"
        [iconClass]="'fa-lightbulb-on fa-lg' + ' ' + (reaction.user_reaction=='Insightful' ? 'fa-solid text-yellow':'fal')" [iconHoverClass]="'text-yellow'"
        (buttonClick)="onReactionSelectClick('Insightful')"></fpc-button>
    </div>
  </ng-container>

  <ng-container *ngIf="showFavouriteButton">
    <div class="btn-group w-100" role="group" aria-label="Default button group">
      <fpc-button class="flex-fill" [buttonColorClass]="'outline-white'" [title]="isFavourite ? 'Remove from favourite' : 'Add to favourite'"
        [ngStyle]="{ '--button-bg-color': '#ffc107' }" [buttonClass]="'w-100' + ' ' + (isFavourite? 'after-border':'')" [text]="'Favourite'"
        [buttonHoverClass]="'after-border'" [iconClass]="'fas fa-star ' + (isFavourite ? 'text-yellow':'text-gray')" [iconHoverClass]="'text-yellow'"
        (buttonClick)="isFavourite ? onFavouriteRemoveClick() : onFavouriteAddClick()"></fpc-button>
    </div>
  </ng-container>
</ng-container>

<ng-container *ngIf="reactionLayout === 'card-reactions'">
  <fpc-dropdown [id]="id" [iconLayout]="true" [buttonColorClass]="buttonColorClass" [iconClass]="iconClass" [iconSize]="iconSize" [buttonText]="buttonText"
    [buttonMenuPlacement]="menuPlacement">
    <ng-container>
      <div ngbDropdownItem class="dropdown-item" *ngIf="cardContextMenu.enableFavourite" [title]="cardModel.isFavourite ? 'Remove favourite':'Add favourite'">
        <h4 class="mb-0" (click)="cardModel.isFavourite ? onFavouriteRemoveClick($event, cardControlContextMenu) : onFavouriteAddClick($event, cardControlContextMenu)">
          {{cardModel.isFavourite ? 'Remove favourite':'Add favourite'}}
        </h4>
      </div>
      <div ngbDropdownItem class="dropdown-item" *ngIf="cardContextMenu.enableSendEmail" title="Send email">
        <h4 class="mb-0">
          <a href="mailto:{{cardModel.email}}" class="stretched-link">Send Email</a>
        </h4>
      </div>

      <div ngbDropdownItem class="dropdown-item" *ngIf="cardContextMenu.enableSkypeMessage" title="Instant message">
        <h4 class="mb-0">
          <a [href]="getSantizeUrl(cardModel.skypeLink)" class="stretched-link">Instant message</a>
        </h4>
      </div>
      <ng-container *ngIf="cardModel.reactions && !cardModel.reactions.disableReactions">
        <div ngbDropdownItem class="dropdown-item" title="Like" (click)="onReactionSelectClick(cardModel.id, 'Like')"
          [ngClass]="cardModel?.reactions?.user_reaction=='Like' ? 'active':''">
          <h4 class="mb-0">Like</h4>
          <i class="like fa-light fa-thumbs-up fa-lg"></i>
        </div>

        <div ngbDropdownItem class="dropdown-item" title="Celebrate" (click)="onReactionSelectClick(cardModel.id, 'Celebrate')"
          [ngClass]="cardModel?.reactions?.user_reaction=='Celebrate' ? 'active':''">
          <h4 class="mb-0">Celebrate</h4>
          <i class="fa-light fa-hands-clapping fa-flip-horizontal fa-lg"></i>
        </div>

        <div ngbDropdownItem class="dropdown-item" title="Love" (click)="onReactionSelectClick(cardModel.id, 'Love')"
          [ngClass]="cardModel?.reactions?.user_reaction=='Love' ? 'active':''">
          <h4 class="mb-0">Love</h4>
          <i class="fa-light fa-heart fa-lg"></i>
        </div>

        <div ngbDropdownItem class="dropdown-item" title="Insightful" (click)="onReactionSelectClick(cardModel.id, 'Insightful')"
          [ngClass]="cardModel?.reactions?.user_reaction=='Insightful' ? 'active':''">
          <h4 class="mb-0">Insightful</h4>
          <i class="fa-light fa-lightbulb-on fa-lg"></i>
        </div>
      </ng-container>
    </ng-container>
  </fpc-dropdown>
</ng-container>