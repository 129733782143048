import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccordionComponent } from './accordion.component';
import { NgbAccordionModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ButtonModule } from '../buttons/button/button.module';

@NgModule({
  declarations: [AccordionComponent],
  imports: [CommonModule, NgbModule, NgbAccordionModule, ButtonModule],
  exports: [AccordionComponent],
})
export class AccordionModule { }
