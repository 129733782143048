<fpc-input-field-label 
  [class]="(labelPosition == 'top') ? '' : (labelPosition == 'right') ? 'd-flex flex-row-reverse gap-2 justify-content-end align-items-center' : 'd-flex gap-2 align-items-center'"
  [label]="_label"
  [labelAdditionalInfo]="_labelAdditionalInfo" 
  [required]="required" 
  [popoverText]="_popoverText" 
  [popoverTitle]="_popoverTitle"
  [labelColor]="labelColor"
  [labelClass]="labelClass" 
  [iconSize]="iconSize">
  
  <input 
  class="mb-0 form-check-input" 
  type="checkbox" 
  [formControl]="control" 
  [name]="control" 
  [required]="required"
  [attr.checked]="control.value ? '' : null"
>
</fpc-input-field-label>

<fpc-input-error [formControl]="control" [customErrors]="_customErrors"></fpc-input-error>