import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from '../../buttons/button/button.module';
import { InputFieldLabelModule } from '../input-field-label/input-field-label.module';
import { InputErrorModule } from '../input-error/input-error.module';
import { ImageUploaderComponent } from './image-uploader.component';

@NgModule({
  declarations: [ImageUploaderComponent],
  imports: [
    CommonModule, 
    NgbModule, 
    FormsModule, 
    ReactiveFormsModule, 
    ButtonModule, 
    InputFieldLabelModule, 
    InputErrorModule
  ],
  exports: [ImageUploaderComponent],
})
export class ImageUploaderModule {}
