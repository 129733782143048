import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { InputErrorComponent } from './input-error.component';

@NgModule({
  declarations: [InputErrorComponent],
  imports: [CommonModule, FormsModule, NgbPopoverModule],
  exports: [InputErrorComponent],
})
export class InputErrorModule {}
