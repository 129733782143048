<ng-container *ngIf="_customErrors">
    <ng-container *ngFor="let error of [_customErrors]">
        <ng-container *ngIf="control.touched">           
            <p *ngIf="error.required" class="h6 text-red mt-2 mb-0">This field is required</p>
            <p *ngIf="error.minlength" class="h6 text-red mt-2 mb-0">Min length required is {{error.minlength?.requiredLength}}</p>
            <p *ngIf="error.maxlength" class="h6 text-red mt-2 mb-0">Max length required is {{error.maxlength?.requiredLength}}</p>
            <p *ngIf="error.invalidEmail" class="h6 text-red mt-2 mb-0">{{error.invalidEmail}}</p>
            <p *ngIf="error.customError" class="h6 text-red mt-2 mb-0">{{error.customError}}</p> 
        </ng-container>
    </ng-container>
</ng-container>