<!-- Bookings table -->
<div class="table-view" [ngClass]="[(systemBookings | filter:legendArray:'status':'label').length==0 ? 'empty' : '']">
  <div class="table_container border table-responsive fixed_header">
    <!-- Table -->
    <table class="table table-striped" style="border-radius: 0;">
      <thead>
        <tr>
          <th *ngFor="let col of cols" [ngClass]="col.class" scope="col" [sortable]="col.propertyName" (sort)="onSort($event)">
            <div class="actions">
              <div class="search">
                <h4>
                  <input class="aheaders" [searchable]="col.propertyName" (search)="onSearch($event)" type="text" value="{{col.title}}"
                    [attr.data-column]="col.propertyName" [attr.data-value]="col.title" placeholder="Search" [title]="col.title">
                </h4>
              </div>
              <div class="sort" title="Ascending">
                <h4><i class="fal fa-long-arrow-up"></i></h4>
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <!-- | filter:legendArray:'status':'label' -->
      <tbody>
        <tr class="status" [ngClass]="booking.status.class" *ngFor="let booking of systemBookings | filter:legendArray:'status':'label'" (click)="openForm(booking)">
          <ng-container *ngFor="let col of cols; let i = index;">
            <th *ngIf="i == 0" [ngClass]="col.class" scope="row">
              <ng-container [ngTemplateOutlet]="cellTemplate" [ngTemplateOutletContext]="{ bookingg: booking, coll: col }"></ng-container>
            </th>
            <td *ngIf="i > 0" [ngClass]="col.class">
              <ng-container [ngTemplateOutlet]="cellTemplate" [ngTemplateOutletContext]="{ bookingg: booking, coll: col }"></ng-container>
            </td>
          </ng-container>

          <ng-template #cellTemplate let-booking="bookingg" let-col="coll">
            <ng-container *ngIf="col.propertyName.includes('|')">
              <h4 *ngFor="let prop of col.propertyName.split('|')">{{booking[prop]}}</h4>
            </ng-container>
            <h4 *ngIf="!col.propertyName.includes('|')">
              <span *ngIf="col.class != 'labels'">{{col.class == 'date' ? (booking[col.propertyName] | date:'dd/MM/yyyy') :
                (booking[col.propertyName])}}</span>
              <div *ngIf="col.class == 'labels'" class="label" [ngClass]="booking.status.colour">
                <h4>{{booking.status.label}}</h4>
              </div>
            </h4>
          </ng-template>
        </tr>
      </tbody>

    </table>
    <!-- X Table -->
  </div>

  <!-- Empty -->
  <div class="empty_container">
    <div *ngIf="(systemBookings | filter:legendArray:'status':'label').length==0" class="d-flex align-items-center justify-content-center text-center h-100"
      style="height: 20rem;">
      <div class="empty_message pl-3 pr-3 pl-sm-0 pr-sm-0">
        <i class="fal fa-glasses-alt"></i>
        <h2>Nothing found</h2>
        <h3 class="col-12 col-xs-12 col-sm-8 mx-auto">
          Current selection doesn't have any records. Please select different combination.
        </h3>
      </div>
    </div>
  </div>
  <!--X Empty -->
</div>
<!-- X Bookings table -->