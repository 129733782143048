import { Component, Input, OnInit, Injector, forwardRef, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { BaseInputComponent } from '../base-input.directive';
import { FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidatorFn, Validators } from '@angular/forms';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'fpc-input-select',
  templateUrl: './input-select.component.html',
  styleUrls: ['./input-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputSelectComponent),
      multi: true,
    },    
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputSelectComponent),
      multi: true,
    }
  ],
})

export class InputSelectComponent extends BaseInputComponent implements OnInit, AfterViewInit {

  buttonDefaultValue: string;
  isRequired: boolean = false;

  @Input() id: string;
  @Input() labelColor: string;
  @Input() inputClass: string = 'h4';
  @Input() inputButtonText: string;
  @Input() customText: string;
  @Input() labelClass = 'mb-2';
  @Input() container = null;

  constructor(injector: Injector, private cdRef: ChangeDetectorRef) {
    super(injector);    
  }

  ngOnInit(){
    this.isRequired = (this.formControl as FormControl).hasValidator(Validators.required)
  }
 
  ngAfterViewInit(): void {    
    
    this.buttonDefaultValue = this.inputButtonText;

    let formattedControl = this.formatDate(this.formControl.value);

    if(this.formControl.touched) {
      if(this.formControl.value){      
        if (formattedControl instanceof Array) {
          this.inputButtonText = formattedControl[0].description;
        }  else {
          this.inputButtonText = formattedControl;  
        }             
      }
    } else {
      this.inputButtonText = formattedControl ? formattedControl : this.buttonDefaultValue;
    }
  

    this.formControl.valueChanges.subscribe((res) => {
      if(res != undefined || res != null || res?.length>0){
         if (res && res instanceof Array) {
          if (res[0] instanceof Object) {
            this.inputButtonText = res.map(el => el.description).join(', ');
          } else {
            if(res.length > 0){
              if (res.length === 1) {
                this.inputButtonText = res[0];
              } else {
                this.inputButtonText = res.join(', ');
              }
            } else {
              this.inputButtonText = this.buttonDefaultValue;
            }
            
          }
        } else {      
        let date = this.formatDate(res);
          if(this.formControl.touched) {
            if(date){
              this.inputButtonText = date;       
            } else {
              if(!this.formControl.pristine) {
                this.inputButtonText = this.buttonDefaultValue;
              }
            }
          } else {
            this.inputButtonText = date ? date: this.buttonDefaultValue;
          }  
        }  
      } else {
        this.inputButtonText = this.buttonDefaultValue;
      }    
         
    });

    this.cdRef.detectChanges();
  }

  formatDate(input) {
    if(input){
      let date;  

      if (typeof input === 'string') {        
        let isoPattern = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(.\d{3})?Z?/;
        if(isoPattern.test(input)){
          date = new Date(input);
          return this.reateDateString(date)
        }   
        return input;
      } else if (input instanceof NgbDate) { 
          date = new Date(input.year, input.month - 1, input.day);
          return this.reateDateString(date)
      } else if (input instanceof Date) {
          date = input;
          return this.reateDateString(date)
      } else {
        return input;
      }   
      
    } 
  }

  reateDateString(date){
    const day = String(date?.getDate()).padStart(2, '0');
    const month = String(date?.getMonth() + 1).padStart(2, '0');
    const year = date?.getFullYear();  
    return `${day}/${month}/${year}`;     
  }
  
  onDropdownOpen(isAccordionOpen: boolean){
    if(!isAccordionOpen) this.control.markAsTouched();
  }
  
}