<fpc-input-field-label 
[label]="_label" 
[required]="isRequired" 
[popoverText]="_popoverText" 
[popoverTitle]="_popoverTitle"
[labelColor]="labelColor"
[labelClass]="labelClass">
  <div class="form-check form-check-inline" *ngFor="let opt of options">
    <input 
      class="form-check-input" 
      type="radio" 
      [id]="id" 
      [formControl]="control" 
      [placeholder]="_placeholder"
      name="{{name}}" value="{{opt.value}}"
      [required]="required">
    <label class="form-check-label h5 mb-0 fw-bold text-gray-base" [attr.for]="opt.value">{{opt.value}}</label>
  </div>
</fpc-input-field-label>
<fpc-input-error [formControl]="control" [customErrors]="_customErrors"></fpc-input-error>