<nav class="p-0" [ngClass]="isHome ? 'nav' : 'navbar navbar-expand-lg'">
  <div id="nav-wrapper" class="collapse navbar-collapse">
    <div class="w-100">
      <div class="force-overflow">
        <button 
        class="navbar-toggler" 
        type="button" 
        data-bs-toggle="collapse" 
        data-bs-target="#navbarNav1" 
        aria-expanded="false" 
        aria-controls="navbarNav1">
          <strong>Presentational</strong>
        </button>

        <div class="collapse" id="navbarNav1">



          <ul class="nav flex-column">

            <li class="nav-item" routerLinkActive="active" *ngFor="let link of fpComponentsLinks">
              <a class="nav-link" #a (click)="setDataTarget(a)" data-bs-toggle="collapse" [attr.data-bs-target]="dataTarget"
                [routerLink]="link.routerLink"><strong>{{link.title}}</strong></a>

            </li>
          </ul>


          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#form-elements" aria-expanded="false" aria-controls="form-elements">
            <strong>Form elements</strong>
          </button>
          <div class="collapse" id="form-elements">
            <ul class="nav flex-column">

              <li class="nav-item" routerLinkActive="active" *ngFor="let link of fpComponentsFormlinks">
                <a class="nav-link" #a (click)="setDataTarget(a)" data-bs-toggle="collapse" [attr.data-bs-target]="dataTarget"
                  [routerLink]="link.routerLink"><strong>{{link.title}}</strong>
                </a>
              </li>

            </ul>
          </div>


        </div>

        <button class="navbar-toggler mt-4" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav2" aria-expanded="false" aria-controls="navbarNav2">
          <strong>Smart</strong>
        </button>

        <div class="collapse" id="navbarNav2">
          <ul class="nav flex-column">
            <li class="nav-item" routerLinkActive="active" *ngFor="let link of fpCommonLinks">
              <a class="nav-link" #a (click)="setDataTarget(a)" data-bs-toggle="collapse" [attr.data-bs-target]="dataTarget" [routerLink]="link.routerLink" role="tab"
                aria-selected="false"><strong>{{link.title}}</strong></a>
            </li>
          </ul>
        </div>

        <button class="navbar-toggler mt-4" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav3" aria-expanded="false" aria-controls="navbarNav3">
          <strong>Read me</strong>
        </button>

        <div class="collapse" id="navbarNav3">
          <ul class="nav flex-column">
            <li class="nav-item" routerLinkActive="active" *ngFor="let link of fpReadmeLinks">
              <a class="nav-link" #a (click)="setDataTarget(a)" data-bs-toggle="collapse" [attr.data-bs-target]="dataTarget" [routerLink]="link.routerLink" role="tab"
                aria-selected="false"><strong>{{link.title}}</strong></a>
            </li>

          </ul>
        </div>


      </div>
    </div>
  </div>
</nav>