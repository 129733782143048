import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LinkCardComponent } from './link-card.component';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';



@NgModule({
  declarations: [
    LinkCardComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgbModule
  ], 
  exports: [
    LinkCardComponent
  ]
})
export class LinkCardModule { }
