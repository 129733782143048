import { Component, Input } from '@angular/core';
import { LinkListModel } from '../../shared/_models/link-list.model';
import { NavigateService } from '../../shared/_services/navigate.service';

@Component({
  selector: 'fpc-link-list',
  templateUrl: './link-list.component.html',
  styleUrls: ['./link-list.component.scss'],
})

export class LinkListComponent {
  singleElement: boolean = false;

  @Input() model: LinkListModel[] = [];
  @Input() borderClass: string;
  @Input() linkDecoration: string = 'default';
  @Input() linkPosition: string = 'left';
  @Input() fontSize: 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | undefined = 'h2';
  @Input() customNgStyle!: | string | string[] | Set<string> | { [Klass: string]: any };

  constructor(private navigateService: NavigateService) {
    this.singleElement = this.model.length == 1 ? true : false;
  }

  linkAction(link: any) {
    this.navigateService.navigate(link);    
  }  
  
}
