<fpc-input-field-label 
  [class]="(labelPosition == 'top') ? '' : 'd-flex flex-row-reverse gap-2 justify-content-end align-items-center'"
  [label]="_label"
  [labelAdditionalInfo]="_labelAdditionalInfo" 
  [required]="required" 
  [popoverText]="_popoverText" 
  [popoverTitle]="_popoverTitle"
  [labelColor]="labelColor"
  [labelClass]="labelClass"  
  [iconSize]="iconSize">  
  
  <div class="form-check form-switch mb-0">
      <input 
        class="mb-0 form-check-input" 
        type="checkbox" 
        role="switch"
        [formControl]="control" 
        [name]="control" 
        [required]="required"
        [attr.checked]="control.value ? '' : null"
      >
  </div>
</fpc-input-field-label>

<fpc-input-error [formControl]="control" [customErrors]="_customErrors"></fpc-input-error>