import { Component, Input, Injector } from '@angular/core';
import { BaseInputComponent } from '../base-input.directive';

@Component({
  selector: 'fpc-input-field-label',
  templateUrl: './input-field-label.component.html',
  styleUrls: ['./input-field-label.component.scss']
})
export class InputFieldLabelComponent extends BaseInputComponent {
  @Input() iconSize: string = 'md';
  @Input() labelColor: string = 'text-gray-base';
  @Input() labelClass: string = 'h6';
  @Input() required: boolean = false;

  showPopoverBadge: boolean = false;
  
  constructor(injector: Injector) {
    super(injector);
  }
}
