import { AbstractControl, ValidationErrors } from "@angular/forms";

export class CustomValidators {


  // Email form control validator function
  public static emailValidator(control: AbstractControl): ValidationErrors | null {
    if(!control || !control.value) return;
    const regex = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
    
    if(!regex.test(control.value)) return { invalidEmail: 'Invalid email format' };      
  }
}
