import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';

const routes: Routes = [
  { 
    path: 'home',
    component: HomeComponent,
    data: { path:'home'}
  },
  {
    path: 'core',
   loadChildren: ()=> import('./components/lib-components/lib-components.module').then((m)=> m.ComponentLibModule)
  },  
  { path: '**', redirectTo: 'home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
