import {
  Component,
  Injector,
  Input,
  forwardRef
} from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseInputComponent } from '../base-input.directive';

@Component({
  selector: 'fpc-input-number',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputNumberComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputNumberComponent),
      multi: true,
    }
  ]
})
export class InputNumberComponent extends BaseInputComponent {

  @Input() labelColor!: string;
  @Input() labelClass = 'mb-2';
  @Input() iconSize: string;
  @Input() inputClass: string = 'h4';
  @Input() max: number;
  @Input() min: number;

  constructor(injector: Injector) {
    super(injector);
  }
}
