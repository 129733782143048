<div *ngIf="userHasPermission" class="ai-chat-bot">
  <div class="panel" *ngIf="showPanel">

    <div class="header">
      <h3>
        <small>Foster and Partners</small>
        <br />
        <b>Advanced chat search</b>
      </h3>
      <div class="header_controls">
        <fpc-button [title]="'Reset'" [buttonLayoutType]="'icon'" [buttonColorClass]="'primary'" [iconClass]="'fa-regular fa-undo'" (buttonClick)="reset()">
        </fpc-button>

        <div class="vertical-line"></div>

        <fpc-button [title]="'Close'" [buttonLayoutType]="'icon'" [customNgClass]="{'margin-left':' 1rem'}" [buttonColorClass]="'primary'"
          [iconClass]="'fa-regular fa-times'" (buttonClick)="showPanel = !showPanel;"></fpc-button>
      </div>

    </div>

    <div class="content">

      <div class="stage" *ngIf="isResponseLoaded">
        <div class="dot-flashing"></div>
      </div>

      <div class="message-container row m-0" [ngClass]="{'fp-row' : item.sender == 'fp'}" *ngFor="let item of messages">

        <div class="message-content" [ngClass]="(item.sender=='user') ? 'user' : 'fp'">
          <p class="m-0">
            {{item.message}}
          </p>

          <div *ngIf="item.documents" class="mt-2">

            <ngb-accordion #acc="ngbAccordion">
              <ngb-panel class="mb-1" id="1" title="References">

                <ng-template ngbPanelContent>
                  <a [routerLink]="['search']" *ngFor="let doc of item.documents" style="display: block; margin-bottom: 10px;"
                    [queryParams]="{searchTerm: doc.content.filepath, searchLabel: doc.content.filepath, pageSize: 30}">{{doc.content.filepath}}</a>
                </ng-template>

              </ngb-panel>

            </ngb-accordion>
          </div>

        </div>

        <div class="message-avatar" [ngStyle]="{'background': ' url(' + item.avatar  + ')'}" [ngClass]="(item.sender=='user') ? 'user' : 'fp'"></div>

      </div>


      <form class="footer" [formGroup]="messageForm" (ngSubmit)="onSubmit()">
        <textarea [id]="'messageID'" type="text" class="form-control" [formControl]="messageForm.get('message')" [placeholder]="'Type your question...'"
          [name]="'message'" rows="3" cols="40" [required]="true" (keydown.enter)="onSubmit($event)"></textarea>

        <fpc-button [type]="'submit'" [buttonLayoutType]="'icon'" [buttonColorClass]="'primary'" [buttonClass]="'send'" [iconClass]="'fa-light fa-paper-plane-top'"
          [disabled]="!messageForm.valid"></fpc-button>
      </form>
    </div>

  </div>
  <fpc-button class="toggle-btn" (click)="togglePanel()" [text]="'Advanced chat search'" [buttonColorClass]="'primary'"
    [buttonClass]="'rounded-0 border-bottom-0 rounded-top float-end p-3'" [active]="true"></fpc-button>
</div>