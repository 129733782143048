<nav *ngIf="showBreadcrumbs && breadcrumbs" aria-label="breadcrumb">
	<div class="breadcrumb fw-bold flex-nowrap">
		<ng-container *ngFor="let breadcrumb of breadcrumbs; let i = index">
			<h4 *ngIf="i < breadcrumbs.length - 1" class="breadcrumb-item fw-bold mb-0 text-truncate" aria-current="page">
				<a [routerLink]="breadcrumb.url" [queryParams]="breadcrumb.category ? { cardType: breadcrumb.category } : {}">{{breadcrumb.title}}</a>
			</h4>
		</ng-container>
		<h4 class="breadcrumb-item text-truncate active fw-bold mb-0" aria-current="page">
			{{breadcrumbs[breadcrumbs.length -1].title}}
		</h4>
	</div>
</nav>