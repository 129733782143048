import { Component, Injector, forwardRef } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseInputComponent } from '../base-input.directive';

@Component({
  selector: 'fpc-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ImageUploaderComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ImageUploaderComponent),
      multi: true,
    }
  ]
})

export class ImageUploaderComponent extends BaseInputComponent {
  today = new Date();
  formattedExpiryDate: any | undefined;

  picFile: string;
  base64Image: string | ArrayBuffer;
  fileErrorMessage: string | null = null;
  
  constructor(injector: Injector) {
    super(injector);
  }

  resizeImage(blob, w, h, type, callback) {
    const imgSrc = URL.createObjectURL(blob);
    const canvas = document.createElement("canvas");
    const img = new Image();
    img.addEventListener("load", (ev) => {
      if (img.width < w && img.height < h) {
        callback(blob);
      } else {
        const aspect = img.width / img.height;
        if (img.width > img.height) w = h * aspect;
        else h = w / aspect;
        canvas.width = w;
        canvas.height = h;
  
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, w, h);
  
        canvas.toBlob((resizedBlob) => {
          callback(resizedBlob);
        }, type);
  
        URL.revokeObjectURL(imgSrc);
      }
    });
    img.src = imgSrc;
  }
  
  fileInputChange(event?: any) {
    const file = event?.target.files[0];
    const maxFileSize = 2 * 1024 * 1024; // Maximum file size (2 MB)
    const validTypes = ['image/png', 'image/jpeg', 'image/jpg'];

    if (file) {
      this.fileErrorMessage = '';
      // Check file type
      if (!validTypes.includes(file.type)) {
        this.fileErrorMessage = 'Please select a valid image file.';
        return; 
      }

      // Check file size
      if (file.size > maxFileSize) {
        this.fileErrorMessage = `File is too large. Maximum size allowed is ${(maxFileSize / (1024 * 1024)).toFixed(2)} MB.`;
        return; 
      }

      // Proceed with file processing if validations pass
      const reader = new FileReader();
      reader.onload = (event) => {
        const blob = new Blob([event.target.result], { type: file.type });
        this.resizeImage(blob, 400, 400, file.type, (resizedBlob) => {
          this.picFile = URL.createObjectURL(resizedBlob);
  
          const readerBase64 = new FileReader();
          readerBase64.onloadend = () => {
            this.base64Image = readerBase64.result;
            this.formControl.setValue(this.base64Image);
            
          };
          readerBase64.readAsDataURL(resizedBlob);
        });
      };
      reader.readAsArrayBuffer(file);
    }
  }

  fileAreaClick() {
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;    
    fileInput.click();    
  }
}
