import { Component, Input } from '@angular/core';

@Component({
  selector: 'fpc-preloader',
  templateUrl: './preloader.component.html',
  styleUrls: ['./preloader.component.scss']
})
export class PreloaderComponent  {
  @Input() size: 'md' | 'lg';

}
