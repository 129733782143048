<div class="wiz_instruction_card_img_container" [ngStyle]="(imgWidth || imgHeight) ? {'width.px': imgWidth, 'height.px': imgHeight } : {}" >

    <img *ngIf="path" [attr.width]="imgWidth" [attr.height]="imgHeight"class="instruction_card_img" src="{{ path }}" class="mt-2" (click)="(zoom == true) ? showImgModal() : null" [ngClass]="zoom == true ?  'instruction_card_img ' : 'instruction_card_img_off'"/>
    <div class="fullscreen-button">
        <fpc-button *ngIf="zoom == true" [type]="'button'" [title]="'Fullscreen'" [buttonColorClass]="'primary'" [buttonClass]="'badge'" (buttonClick)="showImgModal()"
            [iconClass]="'fa-expand fa-regular'"></fpc-button>
    </div>
</div>
<!-- X Image -->

<!-- Modal with zoomed image -->
<div *ngIf="
                path != undefined &&
                path != ''
              " [style.display]="showModal ? 'block' : 'none'" class="modal" id="imagemodal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
    aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="myModalLabel">
                    Image preview
                </h4>
            </div>
            <div class="modal-body">
                <img src="{{ path }}" id="imagepreview"  />
            </div>
            <div class="modal-footer">
                <fpc-button [text]="'Close'" (buttonClick)="showImgModal()">
                </fpc-button>
            </div>
        </div>
    </div>
</div>
<!-- X Modal with zoomed image -->