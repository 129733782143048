import { ClassProvider, ExistingProvider, FactoryProvider, InjectionToken, Injector, ModuleWithProviders, NgModule, Provider, ValueProvider } from "@angular/core";

import { MsalService, MSAL_INSTANCE, MsalGuard, MsalInterceptor, MsalBroadcastService } from './index';
import { AuthenticationResult, InteractionType } from '@azure/msal-browser';
import { MSAL_GUARD_CONFIG, MSAL_INTERCEPTOR_CONFIG } from './constants';
import { MsalGuardConfiguration } from './msal.guard.config';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MSALInstanceFactory, MSALInterceptorConfigFactory } from "../shared/_factories";

export interface ApiSpec {
  [name: string]: {
    Endpoint: string,
    Client_Id: string,
    Scopes: string[]
  }
}
export interface SharedConfig {
  Client_Id: string;
  Tenant_Id: string;
  Redirect_Uri: string;
  Post_Logout_Redirect_Uri: string;
  Api_Spec: ApiSpec;
  Application_Insights: string;
  [key: string]: any;
}
export const SHARED_CONFIG = new InjectionToken<SharedConfig>('shared-config');
export type ModuleConfigProvider = ValueProvider | ClassProvider | ExistingProvider | FactoryProvider;

@NgModule({
  declarations: [],
  imports: [],
  exports: []
})
export class MsalModule {
  static forRoot(config: SharedConfig | ModuleConfigProvider): ModuleWithProviders<MsalModule> {
    // dynamic (config is Provider) or simple (config is SharedConfig)
    return {
      ngModule: MsalModule,
      providers: [
        (config as ModuleConfigProvider).provide ? (config as Provider) : { provide: SHARED_CONFIG, useValue: config },
        {
          provide: MSAL_INSTANCE,
          useFactory: MSALInstanceFactory,
          deps: [SHARED_CONFIG]
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: MsalInterceptor,
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: MsalInterceptor,
          multi: true
        },
        {
          provide: MSAL_GUARD_CONFIG,
          useValue: {
            interactionType: InteractionType.Redirect
          } as MsalGuardConfiguration
        },
        {
          provide: MSAL_INTERCEPTOR_CONFIG,
          useFactory: MSALInterceptorConfigFactory,
          deps: [SHARED_CONFIG]
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService
      ],
    };
  }

  constructor(authService: MsalService) {
    authService.handleRedirectObservable().subscribe({
      next: (result: AuthenticationResult) => {
        if (result) {
          authService.instance.setActiveAccount(result.account);
          //console.log(result);
        }
      },
      error: (error) => console.log(error)
    });
  }
}