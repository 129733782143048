import { Component, Input, ViewChild, AfterViewInit, OnInit, Output, EventEmitter } from '@angular/core';
import { AccordionPanel } from '../../shared/_models/accordion.model';
import { AccordionService } from './accordion.service';

@Component({
  selector: 'fpc-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss']
})
export class AccordionComponent implements OnInit, AfterViewInit {

  isAccordionOpened: boolean = false;
  inset: boolean = false;

  @Input() accordionPannels!: AccordionPanel[];
  @Input() closeOthers: boolean = null;

  @Input() iconSize: string;
  @Input() accordionTextColorClass: string | undefined;
  @Input() accordionClass: string | undefined;
  @Input() badgeColorClass: string = 'text-primary';
  @Input() titleClass: string | undefined; 
  @Input() subtitleClass: string | undefined = 'h4';
  @Input() badgeClass: string | undefined;
  @Input() accordionSize: 'lg' | 'sm' = 'sm';
  @Input() accordionFlush: boolean = false;

  @Output() toggleAccordionItem: EventEmitter<any> = new EventEmitter();

  @ViewChild('accordionTemplate') accordionTemplate: any;
  @ViewChild('childAccordionTemplate') childAccordionTemplate: any;
  @ViewChild('accordionItem') accordionItem: any;

  constructor(private accordionService: AccordionService){}

  ngOnInit(): void {
    this.inset = this.accordionClass?.includes('inset');
    this.accordionService.recheckCollapsedItems.subscribe(()=>{
      this.checkIfAccordionIsOpen();
    });
  }

  ngAfterViewInit(): void {
    this.checkIfAccordionIsOpen();
  }

  checkCollapsed(selectedItem, actionItems) {
    this.isAccordionOpened = !selectedItem._collapsed ? false : true;
    this.toggleAccordionItem.emit(selectedItem)
    if (!this.isAccordionOpened){
      actionItems?.forEach((item: any) => {
        item.active = false;
      })
    }
  }

  checkIfAccordionIsOpen() {
    this.accordionTemplate._items._results.forEach((item) => {
      this.isAccordionOpened = item._collapsed ? false : true;
    });
  }
}