import { Injectable, Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'categoryPluralTitle'
})
@Injectable({
  providedIn: 'root'
})
export class CategoryPluralTitlePipe implements PipeTransform {
  transform(link: string, reverse: boolean = false): string {
    if (reverse) {
      switch (link.toLowerCase()) {
        case 'apps':
          return "App";
        case 'communities':
          return "Community";
        case 'documents':
          return "Document";
        case 'projects':
          return "Project";
        default:
          return link.substring(0, 1) + link.substring(1, link.length);
      }
    }
    else {
      switch (link.toLowerCase()) {
        case 'app':
          return "Apps";
        case 'community':
          return "Communities";
        case 'document':
          return "Documents";
        case 'project':
          return "Projects";
        default:
          return link.substring(0, 1) + link.substring(1, link.length);
      }
    }
  }
}