import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputFieldLabelModule } from '../input-field-label/input-field-label.module';
import { DropdownModule } from '../../dropdown/dropdown.module';
import { InputCheckBoxComponent } from './input-check-box.component';
import { ButtonModule } from '../../buttons/button/button.module';
import { InputErrorModule } from '../input-error/input-error.module';

@NgModule({
  declarations: [InputCheckBoxComponent],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    InputFieldLabelModule,
    DropdownModule,
    ButtonModule,
    InputErrorModule
  ],
  exports: [InputCheckBoxComponent],
})
export class InputCheckBoxModule { }
