import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { PreviewWrapperComponent } from "./components/preview-wrapper/preview-wrapper.component";
import { ShowcaseSectionTabComponent } from "./components/showcase-section-tab/showcase-section-tab.component";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from "@angular/router";
import { ShowcaseSidenavComponent } from './components/showcase-sidenav/showcase-sidenav.component';
import { TableApiPropFilter } from "./pipes/table-api-prop.pipe";

@NgModule({
  declarations: [
    PreviewWrapperComponent,
    ShowcaseSectionTabComponent,
    ShowcaseSidenavComponent,
    TableApiPropFilter
  ],
  imports: [
    CommonModule,
    NgbModule,
    RouterModule
  ],
  exports: [
    PreviewWrapperComponent,
    ShowcaseSectionTabComponent,
    ShowcaseSidenavComponent,
    TableApiPropFilter
  ]
})
export class SharedModule { }
