import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'fpc-collapse',
  templateUrl: './collapse.component.html',
  styleUrls: ['./collapse.component.scss']
})
export class CollapseComponent implements OnInit {

  @Input() id: string;
  @Input() buttonColorClass: string = 'outline-primary';
  @Input() buttonClass: string;
  @Input() iconClass: string;
  @Input() iconSize: string;
  @Input() isCollapsed: boolean = false;
  @Input() buttonText: string;
  @Input() iconLayout: boolean = false;
  @Input() iconStyle: any;
  @Input() title: string;
  @Input() titleIconClass: string;
  @Input() disables: boolean = false;
  @Input() active: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
