import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbDropdownModule  } from '@ng-bootstrap/ng-bootstrap';
import { ButtonModule } from '../buttons/button/button.module';
import { DropdownComponent } from './dropdown.component';

@NgModule({
  declarations: [DropdownComponent],
  imports: [ CommonModule, ButtonModule, NgbDropdownModule ],
  exports: [DropdownComponent]
})
export class DropdownModule {}
