<div ngbDropdown 
  class="d-inline-block w-100" 
  #buttonDrop="ngbDropdown" 
  [attr.placement]="buttonMenuPlacement ? buttonMenuPlacement : ['left', 'right']"
  [autoClose]="autoclose"
  [container]="container"
  (openChange)="isDropdownOpen($event)">
  <fpc-button 
    [buttonLayoutType]="'dropdown-button'" 
    [id]="id"
    [buttonColorClass]="buttonColorClass" 
    [buttonClass]="buttonClass" 
    [text]="buttonText"
    [iconLayout]="iconLayout" 
    [iconSize]="iconSize" 
    [iconClass]="iconClass" 
    [disabled]="disabled" 
    [active]="buttonDrop.isOpen() || buttonActive"
    (buttonClick)="onButtonClick($event)" ngbDropdownToggle></fpc-button>
  <div *ngIf="!disabled" #ddm ngbDropdownMenu [attr.aria-labelledby]="id" [class]="menuClass + ' mw-100'">
      <ng-content></ng-content>
  </div>
</div>