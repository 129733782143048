<ngb-accordion #a="ngbAccordion">
	<ngb-panel>
		<ng-template ngbPanelHeader let-opened="opened">
			<div class="accordion-button custom-header justify-content-between" [class.collapsed]="!opened">
				<h2 class="m-0">{{accordionPannel.title}}</h2>
				<button ngbPanelToggle class="btn btn-link p-0">Toggle btn</button>
				<fpc-button [buttonLayoutType]="'icon'" [buttonColorClass]="'primary'" [iconClass]="'fal fa-arrow-left'"></fpc-button>
			</div>
		</ng-template>
		<ng-template ngbPanelContent>
			<p>{{accordionPannel.content}}</p>
			<ng-container *ngIf="accordionPannel.template">
				<ng-container *ngTemplateOutlet="accordionPannel.template"></ng-container>
			</ng-container>
		</ng-template>
	</ngb-panel>
</ngb-accordion>