import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  matched = this.observer.isMatched('(max-width: 700px)');
  public dataTarget: string;

  constructor(private observer: BreakpointObserver){}

  ngOnInit(): void {}

  setDataTarget(a){
    this.observer
    .observe(['(max-width: 700px)'])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.dataTarget = '#nav-wrapper'; 
      
      } else {
        this.dataTarget = null;
      }
    });
  }
}
