import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CategoryDropdownTitleCasePipe } from './_pipes/category-dropdown-title-case.pipe';
import { CategoryPluralTitlePipe } from './_pipes/category-plural-title.pipe';
import { TruncatePipe } from './_pipes/truncate.pipe';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbDateTranslatePipe } from './_pipes/ngbDateTranslate.pipe';
import { HttpClientModule } from '@angular/common/http';
import { AiChatBotService } from '../../smart/shared/_services/ai-chat-bot.service';
import { APP_CONFIG } from '../../smart/shared/_helpers/app.config';

import {MbpFilterOrderPipe} from './_pipes/mbpFilterOrder.pipe'

@NgModule({
  declarations: [
    CategoryDropdownTitleCasePipe,
    CategoryPluralTitlePipe,
    TruncatePipe,
    NgbDateTranslatePipe,
    MbpFilterOrderPipe
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule
  ],
  exports: [
    CategoryDropdownTitleCasePipe,
    CategoryPluralTitlePipe,
    TruncatePipe,
    NgbDateTranslatePipe,
    MbpFilterOrderPipe
  ],
  providers: [
    AiChatBotService,
    { provide: APP_CONFIG, useValue: 'localhost' }
  ]
})
export class PresentationalSharedModule { }
