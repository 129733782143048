import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterComponent } from './filter.component';

import { NgbCollapseModule  } from '@ng-bootstrap/ng-bootstrap';
import { DropdownModule } from '../dropdown/dropdown.module';
import { InputSearchModule } from '../form-elements/input-search/input-search.module';
import { ButtonModule } from '../buttons/button/button.module';
import { PresentationalSharedModule } from "../../shared/presentational-shared.module";

@NgModule({
    declarations: [
        FilterComponent
    ],
    exports: [
        FilterComponent
    ],
    imports: [
        CommonModule,
        NgbCollapseModule,
        DropdownModule,
        InputSearchModule,
        ButtonModule,
        PresentationalSharedModule
    ]
})
export class FilterModule { }
