<fpc-input-field-label 
    [label]="_label"
    [labelAdditionalInfo]="_labelAdditionalInfo" 
    [required]="required"
    [popoverText]="_popoverText"
    [popoverTitle]="_popoverTitle" 
    [labelColor]="labelColor" 
    [labelClass]="labelClass" 
    [iconSize]="iconSize">
        <textarea 
            [id]="id" 
            type="text" 
            class="form-control h4" 
            [ngClass]="inputClass" 
            [formControl]="control" 
            [placeholder]="_placeholder" 
            [name]="control"
            [required]="required"
            rows="5">
        </textarea>
</fpc-input-field-label>
<fpc-input-error [formControl]="control" [customErrors]="_customErrors"></fpc-input-error>