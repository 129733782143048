import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CategoryButtonComponent } from './category-button.component';
import { PresentationalSharedModule } from '../../../shared/presentational-shared.module';
import { ButtonModule } from '../../buttons/button/button.module';

@NgModule({
  declarations: [CategoryButtonComponent],
  imports: [CommonModule, PresentationalSharedModule, ButtonModule],
  exports: [CategoryButtonComponent],
})
export class CategoryButtonModule {}
