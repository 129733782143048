import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { compare, NgbSortableHeader, SortEvent } from '../../shared/_directives/ngb-sortable-header.directive';
import { SearchEvent } from '../../shared/_directives/search-header.directive';
import { Booking } from '../../shared/_models/booking';


@Component({
  selector: 'fpc-booking-table',
  templateUrl: './booking-table.component.html',
  styleUrls: ['./booking-table.component.scss']
})
export class BookingTableComponent implements OnInit {

  @Input() systemBookingsOriginal: any;
  @Input() legendArray: string[] = [];

  @Output() openFormEvent = new EventEmitter<any>();
  @Output() loadMoreEvent = new EventEmitter<any>();

  _systemBookings: Booking[];
  set systemBookings(value: Booking[]) {
    this._systemBookings = value;
  }

  get systemBookings(): Booking[] {
    this._systemBookings = this.systemBookingsOriginal;
    const filterSectionItems = () => {
      let newItems = [];
      Object.keys(this.searchData).forEach((column) => {
        const value = this.searchData[column];
        newItems = (newItems.length > 0 ? newItems : this._systemBookings).filter((item) => {
          if (column.indexOf('.') > -1)
            return ("" + item[column.split('.')[0]][column.split('.')[1]]).toLowerCase().includes(("" + value.toLowerCase()))
          else
            return ("" + item[column]).toLowerCase().includes(("" + value.toLowerCase()))
        });
      })
      return newItems;
    }
    return Object.keys(this.searchData).length > 0 ? filterSectionItems() : this._systemBookings;
  }

  cols = [
    { title: "Project", propertyName: "project", class: "title" },
    { title: "Group", propertyName: "group", class: "description" },
    { title: "Submitted by", propertyName: "submittedBy", class: "description" },
    { title: "On behalf of", propertyName: "behalfOf", class: "description" },
    { title: "Date submitted", propertyName: "dateSubmitted", class: "date" },
    { title: "Work source", propertyName: "workSource", class: "description" },
    { title: "Deadline", propertyName: "deadline", class: "date" },
    { title: "Status", propertyName: "status.label", class: "labels" },
    { title: "Booking ID", propertyName: "bookingId", class: "description" },
  ];

  searchData: any = {};


  constructor() { }

  ngOnInit(): void {
    document.querySelector(".table_container")?.addEventListener("scroll", (event) => {
      event.stopPropagation();
      this.loadMoreEvent.emit(true);
    })
  }

  openForm(booking) {
    this.openFormEvent.emit(booking);
  }


  @ViewChildren(NgbSortableHeader) headers: QueryList<NgbSortableHeader>;

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    // sorting
    if (direction === '') {
      this.systemBookingsOriginal = [...this.systemBookingsOriginal].sort((a, b) => {
        return compare(a['originalIndex'], b['originalIndex']);
      })
    } else {
      this.systemBookingsOriginal = [...this.systemBookingsOriginal].sort((a, b) => {
        let res;
        if (column.indexOf('|') > -1) {
          const split = column.split('|');
          res = compare(a[split[0]], b[split[0]]);
        }
        else {
          res = compare(a[column], b[column]);
        }
        return direction === 'asc' ? res : -res;
      });
    }
  }

  onSearch({ column, value }: SearchEvent) {
    let col = column.indexOf('|') > -1 ? column.split('|')[0] : column;
    if (value) {
      this.searchData[col] = value;
    }
    else {
      delete this.searchData[col];
    }
  }


}
