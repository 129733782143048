import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Options } from 'sortablejs';

@Component({
  selector: 'fpc-sortable-list',
  templateUrl: './sortable-list.component.html',
  styleUrls: ['./sortable-list.component.scss']
})
export class SortableListComponent implements OnInit {

  @Input() id: string = 'sortableId';
  @Input() model: string[] = [];
  @Input() handle = '.grip';
  @Output() changeEvent: EventEmitter<boolean> = new EventEmitter();

  optionsSortable: Options = {
    group: this.id,
    handle: this.handle,
    onEnd: () => {
      this.processDropEnd();
    }
  };

  constructor() { }

  ngOnInit(): void {
  }

  processDropEnd(): void {
    this.changeEvent.emit(true);
  }

}
