import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FpcommonComponent } from './fpcommon.component';



@NgModule({
  declarations: [
    FpcommonComponent
  ],
  imports: [
    CommonModule,
    
  ], 
  exports: [
    FpcommonComponent
  ]
})
export class FPCommonModule { }
