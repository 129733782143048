import { Component, Input, Injector, forwardRef } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseInputComponent } from '../base-input.directive';

@Component({
  selector: 'fpc-input-toggle',
  templateUrl: './input-toggle.component.html',
  styleUrls: ['./input-toggle.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputToggleComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputToggleComponent),
      multi: true,
    }
  ]
})
export class InputToggleComponent extends BaseInputComponent {
  @Input() labelColor!: string;
  @Input() labelPosition: 'top' | 'right' = 'top';
  @Input() iconSize: string = 'md'
  @Input() labelClass!: string;
  
  constructor(injector: Injector) {
    super(injector);
  }
}
