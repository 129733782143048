import { AfterViewInit, Component, EventEmitter, Injector, Input, Output, forwardRef } from '@angular/core';
import { BaseInputComponent } from '../../../presentational/components/form-elements/base-input.directive';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

export enum LEGEND_VIEW_MODE {
  LIST_MODE = 0,
  LEGEND_VIEW = 1
}

@Component({
  selector: 'fpc-legend',
  templateUrl: './legend.component.html',
  styleUrls: ['./legend.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LegendComponent),
      multi: true,
    },
  ],
})

export class LegendComponent extends BaseInputComponent implements AfterViewInit {

  LEGEND_VIEW_MODE = LEGEND_VIEW_MODE;
  legendViewMode = LEGEND_VIEW_MODE.LEGEND_VIEW;
  dataBreakdownSearch: string = "";
  viewSearch: boolean = false;
  _selectedItemsArray: string[] = [];
  
  @Input() set selectedItemsArray(value) {
    if(value[0]?.length>0){     
      this._selectedItemsArray = []; 
      this._selectedItemsArray.push(...value);   
      this.sortSelectedArray();
    } else {
      this._selectedItemsArray = []; 
    }
  }

  get selectedItemsArray() {
    return this._selectedItemsArray;
  }

  @Input() itemsOriginal!: { colorClass: string, iconClass: string, label: string, value?: string }[];
  @Input() key!: { colorClass: string, iconClass: string, label: string, value?: string }[];
  @Input() title!: string;
  @Input() subTitle!: string;
  @Input() application!: string;
  @Input() highlight!: string;
  @Input() legendPlaceHolder!: string;
  @Output() legendChangeEvent: EventEmitter<any> = new EventEmitter();

  filteredAndSortedData: any[];
  searchTerm = '';

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    this.filteredAndSortedData = this.itemsOriginal;
  }

  ngAfterViewInit(): void {
    this.formControl.valueChanges.subscribe((value) => {
      this.searchTerm = value;
      if (value) {
        this.filteredAndSortedData = this.itemsOriginal.filter((item) => item.label.toLowerCase().includes(value.toLowerCase()))
      } else {
        this.filteredAndSortedData = this.itemsOriginal;
      }
      this.sortSelectedArray();
    }
    )
  }

  sortSelectedArray() {
    this.filteredAndSortedData = this.itemsOriginal.filter((item) => item.label.toLowerCase().includes(this.searchTerm?.toLocaleLowerCase())).sort((a, b) => {
      if (this._selectedItemsArray.indexOf(a.label) != -1 && this._selectedItemsArray.indexOf(b.label) == -1) return -1;
      if (this._selectedItemsArray.indexOf(b.label) != -1 && this._selectedItemsArray.indexOf(a.label) == -1) return 1;
      return 0;
    });
  }

  clearLegendSearch(e: Event) {
    e.preventDefault();
    this.formControl.reset();
    this.filteredAndSortedData = this.itemsOriginal;
  }

  selectLegendItem(itemLabel: string) {
    if (this._selectedItemsArray.indexOf(itemLabel) == -1) {
      this._selectedItemsArray.push(itemLabel);
    }
    else {
      this._selectedItemsArray = this._selectedItemsArray.filter(x => x != itemLabel);
    }

    this.sortSelectedArray();
    this.legendChangeEvent.emit(itemLabel);
  }

  selectStudioItem(itemLabel: string, itemValue: string) {
    if (this._selectedItemsArray.indexOf(itemLabel) == -1) {
      this._selectedItemsArray.push(itemLabel);
    }
    else {
      this._selectedItemsArray = this._selectedItemsArray.filter(x => x != itemLabel);
    }
    this.sortSelectedArray();
    this.legendChangeEvent.emit(itemValue)
  }

  selectMBPLegendItem(itemLabel: string) {
    //function is used for MBP and Project assessments in sus bar
    if (this._selectedItemsArray.indexOf(itemLabel) > -1) {
      this._selectedItemsArray = [];
    }
    else if (this._selectedItemsArray.length == 0) {
      this._selectedItemsArray.push(itemLabel);
    }
    else {
      this._selectedItemsArray = [];
      this._selectedItemsArray.push(itemLabel);
    }

    this.sortSelectedArray();
    this.legendChangeEvent.emit(itemLabel);
  }

  selectSUSBARLegendItem(itemLabel: string) {
    if (this._selectedItemsArray.length == 0) {
      this._selectedItemsArray = this.itemsOriginal.filter(x => x.label != itemLabel).map(x => x.label);
    }
    else if (this._selectedItemsArray.indexOf(itemLabel) > -1) {
      this._selectedItemsArray = this._selectedItemsArray.filter(x => x != itemLabel);
    }
    else {
      this._selectedItemsArray.push(itemLabel);
    }

    this.sortSelectedArray();
    this.legendChangeEvent.emit(itemLabel);
  }

  legendClass(label: string) {
    if (this._selectedItemsArray.length == 0) return true;
    if (this._selectedItemsArray.indexOf(label) == -1) {
      return false
    }
    return true
  }
}
