import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InputToggleComponent } from './input-toggle.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from '../../buttons/button/button.module';
import { DropdownModule } from '../../dropdown/dropdown.module';
import { InputFieldLabelModule } from '../input-field-label/input-field-label.module';
import { InputErrorModule } from '../input-error/input-error.module';

@NgModule({
  declarations: [
    InputToggleComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    InputFieldLabelModule,
    DropdownModule,
    ButtonModule,
    InputErrorModule
  ], 
  exports: [
    InputToggleComponent
  ]
})
export class InputToggleModule { }
